import * as THREE from "three";
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { LineSegments2 } from 'three/addons/lines/LineSegments2.js';
import { LineSegmentsGeometry } from 'three/addons/lines/LineSegmentsGeometry.js';
import { COLORS } from "../Global/materials";

export default class ZoomButtons extends THREE.Object3D {
    constructor() {
        super();
        this._build();
    }

    _build() {
        const zoomIn = this._zoomIn();
        zoomIn.position.set(8, -39, 0);
        this.add(zoomIn);
        const zoomOut = this._zoomOut();
        zoomOut.position.set(-8, -39, 0);
        this.add(zoomOut);        
    }

    _zoomIn() {
        const zoomIn = new THREE.Object3D();
        zoomIn.add(this._button("in"));
        zoomIn.add(this._line([-3, 0, 0, 3, 0, 0]));
        zoomIn.add(this._line([0, -3, 0, 0, 3, 0]));
        return zoomIn;
    }

    _zoomOut() {
        const zoomOut = new THREE.Object3D();
        zoomOut.add(this._button("out"));
        zoomOut.add(this._line([-3, 0, 0, 3, 0, 0]));
        return zoomOut;
    }

    _button(name) {
        const geo = new THREE.PlaneGeometry(14, 14);
        const mat = new THREE.MeshBasicMaterial({ color: COLORS.white });
        const button = new THREE.Mesh(geo, mat);
        button.name = name;
        return button;
    }

    _line(points) {
        const geo = new LineSegmentsGeometry();
        geo.setPositions(points);
        const mat = new LineMaterial({ color: COLORS.black, linewidth: 2 });
        const line = new LineSegments2(geo, mat);
        return line;
    }
}