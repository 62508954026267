import React, {useEffect, useMemo, useRef, useState} from "react";
import {ModelManager} from "./SetUps/ModelManager";
import {downloadImage} from "../helpers/CONSTANT";
import {sceneManager} from "./SetUps/SceneManager";
import { CubeManager } from "./SetUps/CubeManager";
import {MouseEventHandlers} from "./Events/MouseEventHandlers";
import {POLYGONS, VARS} from "./Global/variables";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    avaArea,
    polygonsState,
    selectedIndicesState, solarPanel, setBackInput,
    deletePanelIndex,
    panelSettings
} from "./ReactStates/states";
import {addObstaclesSelector, addPanelSelector, deletePanelsSelector, updatePolygonDetailsSelector, updatePolygonSetbacksSelector} from "./ReactStates/updates";
import {loadDrawings} from "./DataUtils/LoadData";


export default function Main({setModelProgress, currentDesign,currentDrawingModeRef,updateDrawingMode
,obstacleTypeRef,setDeleteConfirmationVisible,panelList,arePanelsOK,setPanelsOK}) {

    const refContainer = useRef(null);
    const SceneManager = useMemo(() => sceneManager, []);
    const { scene, camera, renderer, controls, labelRenderer } = SceneManager.getSceneObjects();
    const cubeManager = useMemo(() => new CubeManager(camera, controls), [scene]);
    const modelMeshs = useMemo(() => new ModelManager(scene, setModelProgress, currentDesign), [scene],);
    const [polygons, setPolygons] = useRecoilState(polygonsState);
    const setObstacles = useSetRecoilState(addObstaclesSelector);
    const setPanels = useSetRecoilState(addPanelSelector);
    const deletePanels = useSetRecoilState(deletePanelsSelector);
    const setDeletePanels = useSetRecoilState(deletePanelIndex);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
    const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector);
    const addSetback = useSetRecoilState(updatePolygonSetbacksSelector);
    const setShowPanelSettings = useSetRecoilState(panelSettings);
    const [inputValues, setInputValues] = useRecoilState(setBackInput);
    const selectedIndicesRef = useRef(selectedIndices);
    const setAvailableArea = useSetRecoilState(avaArea);
    const [panel, setPanel] = useRecoilState(solarPanel);
    const [mouseDown, setMouseDown] = useState(false);
    let  mouseEventHandlers =null;

    const captureScreenshot = () => {
        const canvas = renderer.domElement;
        requestAnimationFrame(() => {
            // Create composite canvas for the main scene
            const compositeCanvas = document.createElement("canvas");
            const compositeContext = compositeCanvas.getContext("2d");
            compositeCanvas.width = canvas.width;
            compositeCanvas.height = canvas.height;
            compositeContext.drawImage(canvas, 0, 0);

            const thumbnailCanvas = document.createElement("canvas");
            const thumbnailContext = thumbnailCanvas.getContext("2d");
            thumbnailCanvas.width = canvas.width;
            thumbnailCanvas.height = canvas.height;
            const thumbnailSize = 100;
            const thumbnailMargin = 30;
            const thumbnailX = thumbnailCanvas.width - thumbnailSize - thumbnailMargin;
            const thumbnailY = thumbnailCanvas.height - thumbnailSize - thumbnailMargin;
            thumbnailContext.drawImage(canvas, thumbnailX, thumbnailY, thumbnailSize, thumbnailSize);

            const finalCompositeCanvas = document.createElement("canvas");
            const finalCompositeContext = finalCompositeCanvas.getContext("2d");
            finalCompositeCanvas.width = canvas.width;
            finalCompositeCanvas.height = canvas.height;

            finalCompositeContext.drawImage(compositeCanvas, 0, 0);

            finalCompositeContext.drawImage(thumbnailCanvas, 0, 0);

            const imgData = canvas.toDataURL("image/jpeg", 1.0);

            const thumbnailImage = new Image();
            thumbnailImage.src = imgData;
            thumbnailImage.style.position = "fixed";
            thumbnailImage.style.width = thumbnailSize + "px";
            thumbnailImage.style.height = thumbnailSize + "px";
            thumbnailImage.style.borderRadius = 10 + "px";
            thumbnailImage.style.transition = "all 1s ease";
            thumbnailImage.style.border = "2px solid #1677ff";

            const thumbnailMarginRight = 30;
            const finalX = window.innerWidth - thumbnailSize - thumbnailMarginRight;
            const finalY = window.innerHeight - thumbnailSize - thumbnailMargin;
            thumbnailImage.style.top = thumbnailMargin + "px";
            thumbnailImage.style.right = thumbnailMargin + "px";
            document.body.appendChild(thumbnailImage);
            setTimeout(() => {
                thumbnailImage.style.top = finalY + "px";
                thumbnailImage.style.left = finalX + "px";
                setTimeout(() => {
                    document.body.removeChild(thumbnailImage);
                    downloadImage(imgData, "screenshot.jpg");
                }, 1000);
            }, 100);
        });
    };

    useEffect(() => {
        selectedIndicesRef.current = selectedIndices;
    }, [selectedIndices]);

    useEffect(() => {
        VARS.Current_Project = currentDesign;
        refContainer.current && refContainer.current.appendChild(renderer.domElement);
        refContainer.current.appendChild(labelRenderer.domElement);
        refContainer.current.appendChild(cubeManager.cubeRenderer.domElement);
        renderer.domElement.style.position = 'absolute';
        cubeManager.cubeRenderer.domElement.style.position = 'absolute';
        renderer.domElement.style.top = '0';
        const top = renderer.domElement.height-210;
        cubeManager.cubeRenderer.domElement.style.top = top.toString()+"px";
        renderer.domElement.style.zIndex = '1';
        cubeManager.cubeRenderer.domElement.style.zIndex = '2';
        labelRenderer.domElement.style.zIndex = '3';
        loadDrawings(scene,camera,setPolygons,setObstacles,setPanels,panelList,addSetback,setInputValues, setAvailableArea);
        VARS.measurementLabelCounter= POLYGONS.length-1;
        VARS.setbackLabelCounter = POLYGONS.length-1;


        const animate = () => {
            requestAnimationFrame(animate);
            cubeManager.viewCube.update();
            cubeManager.compass.update();
            renderer.render(scene, camera);
            labelRenderer.render(scene, camera);
            cubeManager.cubeRenderer.render(cubeManager.cubeScene, cubeManager.cubeCamera);
        };
        animate();
    }, [renderer, currentDesign, cubeManager.cubeRenderer]);
    
    useEffect(() => {
        mouseEventHandlers = new MouseEventHandlers(SceneManager, currentDrawingModeRef,obstacleTypeRef,
            updateDrawingMode,polygons,setPolygons,setObstacles,setPanels,setDeletePanels,deletePanels,
            setDeleteConfirmationVisible,selectedIndices,setSelectedIndices,setPolygonDetails,
            modelMeshs,panel,setPanel,setShowPanelSettings,mouseDown,setMouseDown,panelList,arePanelsOK,setPanelsOK);
        
        const mousedownHandler = mouseEventHandlers.handleMouseDown.bind(mouseEventHandlers);
        const mousemoveHandler = mouseEventHandlers.handleMouseMove.bind(mouseEventHandlers);
        const mouseupHandler = mouseEventHandlers.handleMouseUp.bind(mouseEventHandlers);
        const dblclickHandler = mouseEventHandlers.handleDoubleClick.bind(mouseEventHandlers);
        const resizeHandler = SceneManager.handleResize.bind(SceneManager);
        const compassResizeHandler = cubeManager.handleResize.bind(cubeManager);

        window.addEventListener('mousedown', mousedownHandler);
        window.addEventListener('mousemove', mousemoveHandler);
        window.addEventListener('mouseup', mouseupHandler);
        window.addEventListener('dblclick', dblclickHandler);
        window.addEventListener("resize", resizeHandler);
        window.addEventListener("resize", compassResizeHandler);

        // Remove event listeners using the same direct references
        return () => {
            window.removeEventListener('mousedown', mousedownHandler);
            window.removeEventListener('mousemove', mousemoveHandler);
            window.removeEventListener('mouseup', mouseupHandler);
            window.removeEventListener('dblclick', dblclickHandler);
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener("resize", compassResizeHandler);
        };
    }, [currentDrawingModeRef.current, selectedIndices]);

    useEffect(() => {
        window.captureScreenshot = captureScreenshot;
        return () => {
            window.captureScreenshot = null;
        };
    }, [captureScreenshot]);

    return (
        <div id={'canvas'} style={{height: "calc(100vh - 92.8px)"}} ref={refContainer}></div>
    )


}