import {LABELS, POLYGONS, VARS} from "../Global/variables";
import {createLabelDiv} from "../Global/functions";
import {Vector3} from "three";

export const createMeasurementLabels = (point1,point2,i,scene) => {
    const distanceInMeters = point1.distanceTo(point2);
    const totalDistanceInFeet = distanceInMeters * 3.281;
    const totalFeet = Math.floor(totalDistanceInFeet);
    const totalInches = Math.round((totalDistanceInFeet - totalFeet) * 12);

    const measureId = `${VARS.measurementLabelCounter}_${i}`;
    const label = indexToLabel(i).toLowerCase();
    let measurementLabel = createLabelDiv( 'measurementLabel',`${label}: ${totalFeet}'${totalInches}"`)
    measurementLabel.position.lerpVectors(point1, point2, 0.5);
    measurementLabel.visible = false;

    LABELS.measurements[measureId] = measurementLabel;
    scene.add(measurementLabel);

    return {
        point1: point1,
        point2: point2,
        id: measureId,
        measurement: totalDistanceInFeet,
    };

}
export function createNameLabel(position) {
    const label = indexToLabel(POLYGONS.length);
    let segmentLabel = createLabelDiv('SegmentLabel',label)
    segmentLabel.position.lerpVectors(position, position, 0.03)
    return segmentLabel;
}
export function indexToLabel(index) {
    if (index < 0) {
        return "";
    }
    var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    do {
        var remainder = index % 26;
        result = alphabet.charAt(remainder) + result;
        index = Math.floor(index / 26) - 1;
    } while (index >= 0);
    return result;
}
export function updateLabelPositions(event, center) {
    const parentId = event.object.parent.uuid;
    const Polygons = POLYGONS;

    for (const polygon of Polygons) {
        if (polygon.shape.uuid === parentId) {
            for (const line of polygon.edges) {
                const marker1UUID = line.vertexId1;
                const marker2UUID = line.vertexId2;

                if (marker1UUID === event.object.uuid || marker2UUID === event.object.uuid) {
                    const id = line.id;
                    const isMarker1 = marker1UUID === event.object.uuid;
                    const markerPosition = isMarker1 ? center : line.point1;
                    const otherMarkerPosition = isMarker1 ? line.point2 : center;

                    line.point1 = isMarker1 ? center : line.point1;
                    line.point2 = isMarker1 ? line.point2 : center;

                    const distance = markerPosition.distanceTo(otherMarkerPosition) * 3.281;
                    const feet = Math.floor(distance);
                    const inches = Math.round((distance - feet) * 12);
                    const label = LABELS.measurements[id];

                    label.element.innerText = `${feet}'${inches}"`;
                    label.position.lerpVectors(markerPosition, otherMarkerPosition, 0.5);
                    line.measurement = distance;
                }
            }

            polygon.shape.geometry.computeBoundingBox();
            center = new Vector3();
            polygon.shape.geometry.boundingBox.getCenter(center);
            polygon.label.position.lerpVectors(center, center, 0.01);
            break;
        }
    }
}
export function updateSegmentLabel(){
    let polygons=POLYGONS
    for(let index in polygons){
        polygons[index].label.element.innerText = indexToLabel(index)
    }
}

