import * as THREE from 'three'
import { POLYGONS, VARS } from "../Global/variables";
import { sceneManager } from "../SetUps/SceneManager";
import { COLORS } from "../Global/materials";
import { removeObstacleDragControls, setObstacleDragControls } from "./DragControls";

export function deleteObstacle(segmentIndex, obstacleIndex) {
    let selectedSegment = POLYGONS[segmentIndex]
    let selectedObs = selectedSegment.obstructions[obstacleIndex];
    sceneManager.scene.remove(selectedObs.mesh);
    selectedObs.mesh.geometry.dispose();
    selectedObs.mesh.material.dispose();
    selectedSegment.obstructions.splice(obstacleIndex, 1);
    removeObstacleDragControls();
}

export function selectObstacle(selectedIndices, index, setSelectedIndices) {
    const { segmentIndex, obstacleIndex, panelIndex } = selectedIndices;
    let selectedObs = POLYGONS[segmentIndex].obstructions[index];
    if (obstacleIndex === index) {
        setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: null });
        deselectObstacle(selectedObs);
    } else {
        setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: index, panelIndex: null })
        if (obstacleIndex !== null && POLYGONS[segmentIndex].obstructions.length >= obstacleIndex) {
            deselectObstacle(POLYGONS[selectedIndices.segmentIndex].obstructions[obstacleIndex])
        }
        VARS.selectedObs = { originalColor: selectedObs.mesh.material.color.clone(), start: selectedObs.mesh.position.clone(), end: null }
        selectedObs.mesh.material.color.set(COLORS.yellow);
        // setObstacleDragControls(selectedObs.mesh);
    }

}

export function deselectObstacle(selectedObs) {
    selectedObs.mesh.material.color.set(selectedObs.ogColor);
    removeObstacleDragControls();
    VARS.selectedObs.end = selectedObs.mesh.position.clone();
    // resetObsCords(selectedObs);

}

function resetObsCords(selectedObs) {
    const diff = new THREE.Vector3().subVectors(VARS.selectedObs.end, VARS.selectedObs.start);
    // for (let i = 0; i < selectedObs.cords.length - 1; i++) {
    //     let point = selectedObs.cords[i];
    //     selectedObs.cords[i] = new THREE.Vector3(point.x, point.y, point.z).add(diff);
    // }
    let extrudeGeo = new THREE.ExtrudeGeometry(selectedObs.polyShape, { bevelEnabled: false });
    const tolerance = 0.0001;
//     for (let c = 0; c < extrudeGeo.attributes.position.array.length-3; c += 3) {
//         let x = extrudeGeo.attributes.position.array[c]
//         let y = extrudeGeo.attributes.position.array[c + 1]
//         let z = extrudeGeo.attributes.position.array[c + 2]
//         for (let i = 0; i < selectedObs.cords.length; i++) {
//             let point = selectedObs.cords[i];
//             if (Math.abs(x - point.x) < tolerance && Math.abs(y - point.y) < tolerance) {
//                 selectedObs.cords[i] = new THREE.Vector3(point.x, point.y, point.z).add(diff);
//                 extrudeGeo.attributes.position.array[c] = selectedObs.cords[i].x;
//                 extrudeGeo.attributes.position.array[c + 1] = selectedObs.cords[i].y;
//                 if (z == 0) {
//                     extrudeGeo.attributes.position.array[c + 2] = selectedObs.cords[i].z
//                 }
//                 else if (z == 1) {
//                     extrudeGeo.attributes.position.array[c + 2] = selectedObs.cords[i].z + selectedObs.height;
//                 }
//             }
//         }
//     }
    // console.log(selectedObs.cords);
    // console.log(extrudeGeo.attributes.position.array);
    
    
    for (let i = 0; i < selectedObs.cords.length-1; i++) {
        const point = selectedObs.cords[i];
        const newCoord = new THREE.Vector3(point.x, point.y, point.z).add(diff);
        for(let c=0; c<extrudeGeo.attributes.position.array.length;c+=3){
            const x = extrudeGeo.attributes.position.array[c];
            const y = extrudeGeo.attributes.position.array[c+1];
            const z = extrudeGeo.attributes.position.array[c+2];
            if(Math.abs(x - point.x) < tolerance && Math.abs(y - point.y) < tolerance){
                
                extrudeGeo.attributes.position.array[c] = newCoord.x;
                extrudeGeo.attributes.position.array[c + 1] = newCoord.y;
                if(z==0){
                    extrudeGeo.attributes.position.array[c+2] = newCoord.z
                }
                else if(z==1){
                    extrudeGeo.attributes.position.array[c+2] = newCoord.z+selectedObs.height;
                }
            }
        }
        selectedObs.cords[i] = newCoord;
    }
    let wireframeGeometry = new THREE.EdgesGeometry(extrudeGeo);


    selectedObs.mesh.geometry.dispose();
    selectedObs.mesh.geometry = wireframeGeometry;
    selectedObs.mesh.material.transparent = true;
    selectedObs.mesh.material.opacity = 1;
    selectedObs.mesh.material.depthTest = false;
    selectedObs.mesh.material.needsUpdate = true;
    selectedObs.mesh.geometry.attributes.position.needsUpdate = true;
}