import * as THREE from 'three';
import ViewCubeControls from "../Compass/ViewCubeControls"
import { FACES } from '../Global/materials';
import CompassRingController from '../Compass/CompassRingControls';
import { moveCube, moveRing } from '../Compass/Helpers';
import ZoomButtonsControls from '../Compass/ZoomButtonsControls';
// import AngleBarControls from '../Compass/AngleBarControls';

export class CubeManager {
    constructor(camera, controls) {
        this.camera = camera;
        this.controls = controls;
        this.controlChange = this.controlChange.bind(this);
        this.cubeChange = this.cubeChange.bind(this);
        this.compassChange = this.compassChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.initialize();
        return this.getCubeSceneObjects();
    }

    initialize() {

        this.cubeRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        this.cubeRenderer.setPixelRatio(window.devicePixelRatio * 1.5);
        this.cubeRenderer.setSize(210, 210);

        this.cubeScene = new THREE.Scene();
        this.cubeScene.background = null;

        this.cubeCamera = new THREE.PerspectiveCamera(50, 1, 0.1, 1000);
        this.cubeCamera.position.set(0, 0, 110);
        this.cubeCamera.lookAt(0, 0, 0);

        const quat = this.camera.quaternion.clone();        
        const initialQuat = new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, 0));

        this.compassRing = new CompassRingController(this.cubeCamera, this.cubeRenderer.domElement);
        const compass = this.compassRing.getObject();
        this.cubeScene.add(compass);

        this.viewCube = new ViewCubeControls(this.cubeCamera, undefined, this.cubeRenderer.domElement);
        this.viewCube.setQuaternion(quat.invert().multiply(initialQuat));
        const cube = this.viewCube.getObject();
        this.cubeScene.add(cube);

        // this.angleBar = new AngleBarControls(this.cubeCamera, this.cubeRenderer.domElement);
        // const {angleBar, angleBox} = this.angleBar.getObjects();
        // this.cubeScene.add(angleBar);
        // this.cubeScene.add(angleBox);
        // console.log(this.camera.rotation);

        const zoomButtons = new ZoomButtonsControls(this.cubeCamera, this.cubeRenderer.domElement, this.controls);
        const buttons = zoomButtons.getObject();
        this.cubeScene.add(buttons);

        this.controls.addEventListener('change', this.controlChange);
        this.viewCube.addEventListener('angle-change', ({ quaternion, face, angle }) => this.cubeChange(quaternion, face, angle));
        this.compassRing.addEventListener('compass-angle-change', ({ face, angle }) => this.compassChange(face, angle));
        this.cubeRenderer.domElement.addEventListener('mousedown', (event) => this.handleMouseDown(cube, event));
    }

    handleMouseDown(cube, event) {
        if (event.button === 0) {
            const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
            const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
            const raycaster = new THREE.Raycaster();
            raycaster.setFromCamera({ x, y }, this.cubeCamera);
            const intersects = raycaster.intersectObjects(cube.children, true);
            if (intersects.length) {
                this.controls.rotateStart(event);
                window.addEventListener('mousemove', this.handleMouseMove);
                window.addEventListener('mouseup', this.handleMouseUp);
            }
        }
    }

    handleMouseMove(event) {
        this.controls.rotate(event);
    }

    handleMouseUp() {
        window.removeEventListener('mousemove', this.handleMouseMove);
        window.removeEventListener('mouseup', this.handleMouseUp);
    }

    controlChange() {
        const quat = this.camera.quaternion.clone();
        moveCube(quat, this.viewCube);
        moveRing(quat, this.viewCube, this.compassRing);
    }

    cubeChange(quaternion, face, angle) {
        this.controls.removeEventListener('change', this.controlChange);
        this.compassRing.removeEventListener('compass-angle-change', this.compassChange);

        if (face === FACES.top || face === FACES.threed) {
            this.controls.setPhi(angle);
        } else this.controls.setTheta(angle);
        this.controls.update();

        const quat = quaternion.clone();
        moveRing(quat, this.viewCube, this.compassRing);

        this.controls.addEventListener('change', this.controlChange);
        this.compassRing.addEventListener('compass-angle-change', this.compassChange);
    }

    compassChange(face, angle) {
        this.controls.removeEventListener('change', this.controlChange);
        this.viewCube.removeEventListener('cube-change', this.cubeChange);

        this.controls.setTheta(angle);
        this.controls.update();

        const quat = this.camera.quaternion.clone();
        moveCube(quat, this.viewCube);

        this.controls.addEventListener('change', this.controlChange);
        this.viewCube.addEventListener('cube-change', this.cubeChange);
    }

    handleResize() {
        this.cubeRenderer.domElement.style.top = (window.innerHeight - 302.8).toString() + 'px';
        this.cubeRenderer.render(this.cubeScene, this.cubeCamera);
    }

    getCubeSceneObjects() {

        return {
            cubeScene: this.cubeScene,
            cubeCamera: this.cubeCamera,
            cubeRenderer: this.cubeRenderer,
            viewCube: this.viewCube,
            compass: this.compassRing,
            handleResize: this.handleResize
        };

    }
}
