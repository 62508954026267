import { COLORS, LINES, OBSTACLE } from "../Global/materials";
import * as THREE from 'three'
import { ObstacleTypes, POLYGONS } from "../Global/variables";
import { addExtrude, changeObstacleOffset } from "./Modifications";
import { addOffset, createCirGeo, createRecGeo } from "./Shapes";
import { createSegmentPlane } from "../Global/functions";

class ObstacleManager {
    constructor(scene, camera, obstacleType, stopDrawing, updateObstacleList, selectedIndices) {
        this.scene = scene;
        this.camera = camera;
        this.startPoint = null;
        this.endPoint = null;
        this.plane = new THREE.Plane();
        this.box = OBSTACLE.box;
        this.circle = OBSTACLE.circle;
        this.type = obstacleType;
        this.isDrawingStarted = false;
        this.rayCaster = new THREE.Raycaster();
        this.stopDrawing = stopDrawing;
        this.updateObstacleList = updateObstacleList;
        if (typeof selectedIndices === 'object' && selectedIndices !== null) {
            this.selectedSegment = selectedIndices.segmentIndex;
        } else {

            this.selectedSegment = selectedIndices;
        }
    }
    handleMouseDown(point) {
        if (!this.isDrawingStarted) {
            this.startPoint = point;
            this.isDrawingStarted = true;
            this.plane = createSegmentPlane(this.selectedSegment);
        } else {
            switch (this.type) {
                case ObstacleTypes.RECTANGLE:
                    this.box.visible = false;
                    this.completeShape(this.points, 0, 2, COLORS.maroon, "rectangle")
                    break;
                case ObstacleTypes.CIRCLE:
                    this.circle.visible = false;
                    this.completeShape(this.points, 0, 2, COLORS.maroon, "circle")
                    break;
            }
            this.isDrawingStarted = false;
            this.stopDrawing();
        }
    }
    handleMouseMove(point) {
        if (this.isDrawingStarted) {
            this.endPoint = point;
            switch (this.type) {
                case ObstacleTypes.RECTANGLE:
                    this.box.visible = true;
                    this.drawRectangle();
                    break;
                case ObstacleTypes.CIRCLE:
                    this.circle.visible = true;
                    this.drawCircle();
                    break;
            }
        }
    }

    drawRectangle() {
        let { points, g } = createRecGeo(this.startPoint, this.endPoint, this.plane, this.rayCaster, this.camera, this.box);
        const center = new THREE.Vector3();
        g.computeBoundingBox();
        g.boundingBox.getCenter(center);
        this.box.geometry.dispose();
        this.box.geometry = g;
        center.normalize();
        this.box.geometry.attributes.position.needsUpdate = true;
        this.box.renderOrder = 1;
        this.points = points;
    }
    drawCircle() {
        let { points, geo } = createCirGeo(this.startPoint, this.endPoint, this.plane, this.rayCaster, this.camera)
        const line = new THREE.Line(geo, LINES.magentaMaterial.clone());
        this.circle.geometry.dispose();
        this.circle.geometry = line.geometry;
        this.circle.material = line.material;
        this.points = points;
    }
    completeShape(points, height, offset, color, type) {
        const shapePoints = points.map(point => new THREE.Vector2(point.x, point.y));
        const shape = new THREE.Shape(shapePoints);
        let extrudeMesh = addExtrude(points, shape, height, color, this.scene);
        const ret = addOffset(extrudeMesh, points, type, shape, offset, height, this.selectedSegment);
        this.addObstacle(type, ret.shape, ret.points, ret.mesh, height, offset, color)
    }
    addObstacle(type, shape, points, extrudeMesh, height, offset, color) {
        let obs = {
            "type": type,
            "polyShape": shape,
            "cords": points,
            "mesh": extrudeMesh,
            "height": height,
            "ogColor": color,
            "offset": offset
        }
        POLYGONS[this.selectedSegment].obstructions.push(obs);
        let uiData = { type: type, height: height, offset: offset }
        this.updateObstacleList({ newObstacle: uiData, segmentIndex: this.selectedSegment });
    }
}

export default ObstacleManager;
