import * as THREE from 'three'
import { FACES, COLORS } from '../Global/materials';

const toRad = Math.PI / 180;
export default class CompassRing extends THREE.Object3D {
    constructor() {
        super();
        this._build();
    }
    _build() {
        const ringGeo = new THREE.RingGeometry(22, 23);
        const ringMat = new THREE.MeshBasicMaterial({color: COLORS.white});
        const ring = new THREE.Mesh(ringGeo, ringMat);
        this.add(ring);
        
        const arrows = this._createArrows();
        this.add(arrows);        
    }
    _createArrows() {
        const arrows = new THREE.Object3D();
        arrows.add(this._createArrow(0xffffff, 0xe2e2e2, FACES.east, [-23, 0, 0], 0)); //actually points to west
        arrows.add(this._createArrow(0xffffff, 0xe2e2e2, FACES.west, [23, 0, 0], 180)); //to east
        arrows.add(this._createArrow(0xffffff, 0xe2e2e2, FACES.north, [0, -23, 0], 90)); //to south
        arrows.add(this._createArrow(0xf64f4f, 0xd64f4f, FACES.south, [0, 23, 0], -90)); //to north
        return arrows;
    }
    _createArrow(color, hover, name, position, angle) {
        const shape = new THREE.Shape();
        shape.moveTo(-5, 0);
        shape.lineTo(6, 4);
        shape.lineTo(4, 0);
        shape.lineTo(6, -4);

        const geo = new THREE.ShapeGeometry(shape);
        const mat = new THREE.MeshBasicMaterial({color: color});
        const arrow = new THREE.Mesh(geo, mat);
        arrow.name = name;
        arrow.userData.color = color;
        arrow.userData.hover = hover;
        arrow.position.set(...position);
        arrow.rotateZ(angle * toRad)

        return arrow;
    }
}