import { Tooltip, Flex, Popconfirm, message, Button } from "antd";
import {
  DeleteOutlined,
  CameraOutlined,
  DownloadOutlined,
  SaveOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import PhotoDrawer from "./PhotoDrawer";
import { downloadModel } from "../helpers/API";
import { useParams } from "react-router";
import { deleteAllSegments } from "../ThreeJs/Segments/Delete";
import { BsMagic } from "react-icons/bs";
import { PiRulerBold } from "react-icons/pi";

import { useRecoilState, useResetRecoilState } from "recoil";
import {
  magicToggleState,
  polygonsState,
  selectedIndicesState,
} from "../ThreeJs/ReactStates/states";
import { DrawingModes } from "../ThreeJs/Global/variables";
import {
  selectSegment,
  showSelectedSegmentLabel,
  toggleSegmentLabels,
} from "../ThreeJs/Segments/Functions";

const ToolHeader = ({
  theme,
  currentProject,
  polygonUpdate,
  isLoading,
  handleUpdatePolygon,
  handleGenerateDxf,
  updateDrawingMode,
  currentDrawingMode,
  arePanelsOK
}) => {
  const [isPhotoDrawerOpen, setPhotoDrawerOpen] = useState(false);
  const [selectedIndices, setSelectedIndices] =
    useRecoilState(selectedIndicesState);
  const resetPolygonsState = useResetRecoilState(polygonsState);
  const [magicToggle, setMagicToggle] = useRecoilState(magicToggleState);
  const [saveDisabled, setSaveDisabled] = useState(!arePanelsOK);

  const params = useParams();
  const projectId = params?.designID;
  const token = params?.token;

  const togglePhotoDrawer = () => {
    setPhotoDrawerOpen(!isPhotoDrawerOpen);
  };

  const handleMagicToggle = () => {
    setMagicToggle((prev) => !prev);
  };

  useEffect(() => {
    if (magicToggle) {
      selectSegment(
        selectedIndices,
        selectedIndices.segmentIndex,
        setSelectedIndices,
        null
      );
      toggleSegmentLabels(true);
    } else {
      toggleSegmentLabels(false);
      if (selectedIndices.segmentIndex != null) {
        showSelectedSegmentLabel(selectedIndices.segmentIndex);
      }
    }
  }, [magicToggle]);

  useEffect(() => {
    if (selectedIndices.segmentIndex !== null && magicToggle) {
      setMagicToggle((prev) => !prev);
    }
  }, [selectedIndices.segmentIndex]);

  useEffect(() => {
    setSaveDisabled(!arePanelsOK);
  }, [arePanelsOK]);

  const closePhotoDrawer = () => {
    setPhotoDrawerOpen(false);
  };

  const handleDownloadModal = () => {
    if (currentProject && currentProject.model) {
      downloadModel(token, projectId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.download = `${projectId}.zip`;
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.error("Error: Model URL is not valid");
    }
  };

  const handleScreenshot = () => {
    if (window.captureScreenshot) {
      window.captureScreenshot();
    } else {
      console.error("captureScreenshot function is not available");
    }
  };

  const handleDeleteAll = () => {
    deleteAllSegments();
    setSelectedIndices({ segmentIndex: null, obstacleIndex: null });
    resetPolygonsState();
  };

  const handleRulerClick = () => {
    if (currentDrawingMode === DrawingModes.RULER) {
      updateDrawingMode(DrawingModes.NONE);
    } else if (currentDrawingMode === DrawingModes.NONE) {
      updateDrawingMode(DrawingModes.RULER);
    }
  };

  return (
    <Flex
      style={{
        background: theme ? "#141416" : "#ffffff",
        borderBottom: `1px solid ${!theme ? "#e1e1e1" : "#525252"}`,
        zIndex: "999",
        padding: "10px 20px",
      }}
      className="tool_header_container"
      justify="end"
      align="center"
    >
      <Tooltip title="Delete All Segments">
        <Popconfirm
          title="Are you sure you want to delete all segments?"
          onConfirm={handleDeleteAll}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined
            style={{
              fontSize: "20px",
              cursor: "pointer",
              marginLeft: "20px",
              color: "red",
            }}
          />
        </Popconfirm>
      </Tooltip>
      <Tooltip title="Photo Drawer">
        <PictureOutlined
          onClick={togglePhotoDrawer}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        />
      </Tooltip>

      <Tooltip title="Toggle Ruler">
        <PiRulerBold
          onClick={handleRulerClick}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        />
        {/* <IconButton
          src={scale}
          onClick={handleRulerClick}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        /> */}
      </Tooltip>

      <Tooltip title="Magic Toggle">
        <BsMagic
          onClick={handleMagicToggle}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        />

        {/* <IconButton
          src={magic}
          onClick={handleMagicToggle}
          style={{
            fontSize: "15px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        /> */}
      </Tooltip>

      <Tooltip title="Screenshot">
        <CameraOutlined
          onClick={handleScreenshot}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        />
      </Tooltip>

      <Tooltip title="Generate DXF">
        <DownloadOutlined
          onClick={handleGenerateDxf}
          style={{
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "20px",
            color: "gray",
          }}
        />
      </Tooltip>
      <Popconfirm
        title="Are you sure you want to save changes?"
        onConfirm={handleUpdatePolygon}
        okText="Yes"
        cancelText="No"
      >
        <Button
          type="primary"
          icon={<SaveOutlined />}
          style={{ marginLeft: "14px" }}
          disabled={saveDisabled}
        >
          Save Changes
        </Button>
      </Popconfirm>

      <PhotoDrawer
        isPhotoDrawerOpen={isPhotoDrawerOpen}
        closePhotoDrawer={closePhotoDrawer}
      />
    </Flex>
  );
};

export default ToolHeader;