import React, { useEffect, useState } from "react";
import {
  Card,
  Flex,
  Typography,
  Button,
  Row, Col,
} from "antd";
import { MdOutlineCircle, MdCheckBoxOutlineBlank, MdOutlineGridOn } from "react-icons/md";
import { BsTriangle } from "react-icons/bs";
import SegmentDetail from "./SegmentDetail";
import SegmentNavigator from "./SegmentNavigator";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { themeState } from "../lib/atom";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ObstacleDetail from "./ObstacleDetail";
import SolarPanelSettings from "./SolarPanelSettings";
import {
  deleteObstacleIndex,
  deletePanelIndex,
  deleteSegmentIndex,
  panelSettings,
  polygonsState,
  selectedIndicesState
} from "../ThreeJs/ReactStates/states";
import { selectSegment } from "../ThreeJs/Segments/Functions";
import { selectObstacle } from "../ThreeJs/Obstacles/ObstacleFunctions";
import { updatePolygonDetailsSelector, updatePolygonSetbacksSelector } from "../ThreeJs/ReactStates/updates";
import { DrawingModes } from "../ThreeJs/Global/variables";
import CollapseCard from "./CollapseCard";
import Title from "./Title";

const Segment = ({
  updateObstacleType,
  updateDrawingMode,
  currentDrawingMode,
  setDeleteConfirmationVisible,
  panelList
}) => {
  let items;
  const theme = useRecoilValue(themeState);
  const polygonState = useRecoilValue(polygonsState);
  const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
  const setDeleteSegment = useSetRecoilState(deleteSegmentIndex);
  const setDeleteObstacle = useSetRecoilState(deleteObstacleIndex);
  const setDeletePanels = useSetRecoilState(deletePanelIndex);
  const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector);
  const [showObsTab, setShowObsTab] = useState(false);
  const [showPanelSettings, setShowPanelSettings] = useRecoilState(panelSettings);

  const handleClick = (event) => {
    event.stopPropagation();
  }

  useEffect(() => {
    if (!showPanelSettings) {
      updateDrawingMode(DrawingModes.NONE);
    } else if (selectedIndices.obstacleIndex !== null) {
      selectObstacle(selectedIndices, selectedIndices.obstacleIndex, setSelectedIndices);
    }
  }, [showPanelSettings]);

  const handleSegmentClick = (index) => {
    setShowPanelSettings(false);
    selectSegment(selectedIndices, index, setSelectedIndices, setPolygonDetails);
  };

  const handleObstructionClick = (index) => {
    setShowPanelSettings(false);
    selectObstacle(selectedIndices, index, setSelectedIndices)
  };


  const handleDeleteObstacle = (index, obsIndex) => {
    setDeleteObstacle(obsIndex);
    setDeleteConfirmationVisible(true);
  }

  const handleDeleteSegment = (index) => {
    setDeleteSegment(index)
    setDeleteConfirmationVisible(true);
  };

  const handleAddObstruction = () => {
    setShowPanelSettings(false);
    setShowObsTab(true);
  }

  const handleDeletePanels = (segmentIndex) => {
    setDeletePanels(Array.from({ length: polygonState[segmentIndex].panels.length}, (v, i) => i));
    setDeleteConfirmationVisible(true);
  }

  items =
    polygonState.length > 0
      ? polygonState.map((polygon, index) => {
        const segmentName = polygon.label;
        const obstacles = polygon.obstructions;
        const panels = polygon.panels;
        const isSegmentSelected = selectedIndices.segmentIndex === index;

        return {
          key: index,
          label: (
            <Row key={index} align="middle" className="segment-item">
              <Col flex="auto">
                <Typography.Paragraph
                  strong
                  style={{ marginBottom: 0 }}
                  className={`text-${isSegmentSelected ? "blue" : "gray-1"} segment-name`}
                  onClick={() => handleSegmentClick(index)}
                >
                  Segment {segmentName}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Button danger
                  type={"text"}
                  onClick={() => handleDeleteSegment(index)}
                  className="delete-button"
                  icon={<DeleteOutlined
                    className="delete-icon"
                  />}
                />
              </Col>
            </Row>
          ),
          children: (
            <Flex vertical gap={"small"}>
              {(showPanelSettings || panels.length > 0) &&
                <Flex
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >

                  <Flex gap="small"
                    className={`${theme ? (showPanelSettings && isSegmentSelected
                      ? "text-blue"
                      : "text-gray-1 fw-500") : (showPanelSettings && isSegmentSelected
                        ? "text-blue"
                        : "text-gray-1 fw-500")

                      }`}
                    style={{ marginLeft: "40px", }}
                  >
                    <MdOutlineGridOn style={{ fontSize: "17px" }} />

                    <Typography.Text
                      style={{
                        fontSize: "12px",
                        transition: "color 0.3s",
                      }}
                      className={`${showPanelSettings && isSegmentSelected
                          ? "text-blue"
                          : "text-gray-1"
                        } `}
                      onClick={() => {
                        setShowPanelSettings(!showPanelSettings);
                      }}
                    >
                      Solar Panels
                    </Typography.Text>
                  </Flex>

                  <Button
                    danger
                    type={"text"}
                    onClick={() => handleDeletePanels(index)}
                    className="delete-button"
                    style={{ marginRight: "25px" }}
                    icon={<DeleteOutlined className="delete-icon" />}
                  />

                </Flex>
              }
              {obstacles.map((obs, obsIndex) => (
                <Flex

                  style={{
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}

                  key={obsIndex}
                >

                  <Flex gap="small"
                    className={`${theme ? (selectedIndices.obstacleIndex === obsIndex
                      ? "text-blue"
                      : "text-gray-1 fw-500") : (selectedIndices.obstacleIndex === obsIndex
                        ? "text-blue"
                        : "text-gray-1 fw-500")

                      }`}
                    style={{ marginLeft: "40px", }}
                  >
                    {obs.type === "rectangle" ? (
                      <MdCheckBoxOutlineBlank style={{ fontSize: "17px" }} />
                    ) : obs.type === "circle" ? (
                      <MdOutlineCircle style={{ fontSize: "17px" }} />
                    ) : (
                      <BsTriangle style={{ fontSize: "17px" }} />
                    )}

                    <Typography.Text
                      style={{
                        fontSize: "12px",
                        transition: "color 0.3s",
                      }}
                      className={`${selectedIndices.obstacleIndex === obsIndex && isSegmentSelected
                          ? "text-blue"
                          : "text-gray-1"
                        } `}
                      onClick={() => {
                        handleObstructionClick(obsIndex);
                      }}
                    >
                      {`${obs.type.charAt(0).toUpperCase() + obs.type.slice(1).toLowerCase()}  `}
                      {obsIndex + 1}
                    </Typography.Text>
                  </Flex>

                  <Button
                    danger
                    type={"text"}
                    onClick={() => handleDeleteObstacle(index, obsIndex)}
                    className="delete-button"
                    style={{ marginRight: "25px" }}
                    icon={<DeleteOutlined className="delete-icon" />}
                  />

                </Flex>

              ))}
              {isSegmentSelected && (
                <Flex vertical gap={"small"} style={{ alignItems: "flex-start"}}>
                  <Button
                    type={"text"}
                    className={`text-gray-1`}
                    onClick={() => handleAddObstruction()}
                    style={{ paddingLeft: '40px', textAlign: 'left', width: '100%', justifyContent: 'flex-start' }}
                  >
                    <PlusOutlined /> Add Obstruction
                  </Button>
                  {panels.length === 0 && !showPanelSettings &&
                    <Button
                      type={"text"}
                      className={`text-gray-1`}
                      onClick={() => setShowPanelSettings(true)}
                      style={{ paddingLeft: '40px', textAlign: 'left', width: '100%', justifyContent: 'flex-start' }}
                    >
                      <PlusOutlined /> Add Panels
                    </Button>
                  }
                </Flex>
              )
              }
            </Flex>
          ),
        }
          ;
      })
      : (
        <div key="empty" style={{ textAlign: 'center', padding: '20px' }}>
          <h1>No segment Found</h1>
        </div>
      );


  const onChange = (key) => {
  };
  const title = (
    <Flex justify="space-between" align="center">
      <Title title="Segments Navigator" />
    </Flex>
  );
  const titleDetails = (
    <Flex justify="space-between" align="center" className="text-black-1 my-0">
      <Title title="Segment Details" />
    </Flex>
  );
  const titleSettings = (
    <Flex justify="space-between" align="center" className="text-black-1 my-0">
      <Title title="Solar Panel Settings" />
    </Flex>
  );
  return (
    <div className="relative">
      <Flex className="segment__position" vertical align="end" onClick={handleClick}>
        <CollapseCard 
          card={
            <Flex gap={"small"} align="start">
              <SegmentNavigator
                items={items}
                onChange={onChange}
                updateDrawingMode={updateDrawingMode}
                currentDrawingMode={currentDrawingMode}
                updateObstacleType={updateObstacleType}
                setShowObsTab={setShowObsTab}
                showObsTab={showObsTab}
                setShowPanelSettings={setShowPanelSettings}
                showPanelSettings={showPanelSettings}
              />
            </Flex>
          }
          label={title}
        />
        {/* TODO: and if statement for segmnet as well*/}
        {selectedIndices.segmentIndex !== null ? (
          <CollapseCard
            card={
              <SegmentDetail />
            }
            label={titleDetails}
          />  
        ) : null}
        {selectedIndices.obstacleIndex !== null ? (
          <CollapseCard
            card={
              <Card style={{ width: "275px" }} bodyStyle={{ padding: "10px" }}>
                <ObstacleDetail
                />
              </Card>
            }
            label="Obstacle Details"
          />
        ) : null}
        {showPanelSettings ? (
          <CollapseCard 
            card={
              <SolarPanelSettings
                updateDrawingMode={updateDrawingMode}
                setShowPanelSettings={setShowPanelSettings}
                deleteConfirmation={setDeleteConfirmationVisible}
                panelList={panelList}
              />
            }
            label={titleSettings}
          />
        ) : null}
        {/*<SegmentSetting titleDetails={titleDetails} />*/}
      </Flex>
    </div>
  );
};

export default Segment;
