import * as THREE from 'three';
import { FACES } from '../Global/materials';

const TWOPI = 2 * Math.PI;
const HALFPI = Math.PI / 2;

export function calculateYDelta(from, to, z) {
    if (to === Math.PI && from < 0) {
        to = - Math.PI;
    }
    if (z !== 0) {
        if (from < 0) {
            from = - Math.PI - from;
        } else {
            from = Math.PI - from;
        }
    }
    const direct = to - from;

    return direct;
}

export function calculateZDelta(from, to, side) {
    let direct = to - from;
    if (side !== 0) direct -= Math.PI;
    return direct
}
export function calculateDelta(from, to) {
    const direct = to - from;
    const altA = direct - TWOPI;
    const altB = direct + TWOPI;
    if (Math.abs(direct) > Math.abs(altA)) {
        return altA;
    }
    else if (Math.abs(direct) > Math.abs(altB)) {
        return altB;
    }
    return direct;
}
function translateYToZ(y, z) {
    if (z !== 0) {
        if (y === 0) {
            return Math.PI;
        } else if (y < 0) {
            return Math.abs(- Math.PI - y);
        } else {
            return (Math.PI + y);
        }
    } else {
        if (y === 0) {
            return 0;
        } else if (y < 0) {
            return Math.abs(y);
        } else {
            return (TWOPI) - y;
        }
    }
}

export function moveCube(quat, cube) {
    const initialQuat = new THREE.Quaternion().setFromEuler(new THREE.Euler(HALFPI, 0, 0));
    cube.setQuaternion(quat.invert().multiply(initialQuat));

    const obj = cube.getObject();
    if (Math.abs(obj.rotation.x).toFixed(3) === HALFPI.toFixed(3)) {
        obj.getObjectByName(FACES.top).visible = false;
        obj.getObjectByName(FACES.threed).visible = true;
    } else {
        obj.getObjectByName(FACES.threed).visible = false;
        obj.getObjectByName(FACES.top).visible = true;
    }
}

export function moveRing(quat, cube, ring) {
    const cubeRotation = cube.getObject().rotation;
    const z = Number(new THREE.Euler().setFromQuaternion(quat).z.toFixed(0));
    ring.getObject().rotation.z = -translateYToZ(cubeRotation.y, z);
}