import { message } from "antd";
export const API_URL = process.env.REACT_APP_API_URL || "https://api.staging.eagleyedrone.com/v1"

export const DEV_MOD = process.env.REACT_APP_DEV_MODE==="true"

export const LAT = "42.80036711341816"

export const LNG = "-71.19690712541342";
export const MODEL_URL = `https://maps.googleapis.com/maps/api/staticmap?center=${LAT},${LNG}&size=600x600&scale=2&zoom=19&maptype=satellite&key=AIzaSyAo1viD-Ut0TzXTyihevwuf-9tv_J3dPa0`


export const handleErrors = (err) => {
  if (err && err.response) {
    if (err.response.data.error) {
      message.error(err.response.data.error);
    } else {
      let errors = err.response.data.errors;
      Object.keys(errors).map((key) => {
        let errorValue = errors[key];
        message.error(errorValue);
      });
    }
  }
};

export function convertDMSToDD(dmsLat, latRef, dmsLng, lngRef) {
  // Helper function to parse fraction strings (e.g., "41/1" or "266456/10000")
  const parseFraction = (fraction) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    return denominator ? numerator / denominator : NaN;
  };

  // Helper function to parse DMS string format (e.g., "43 deg 6' 50.10\"")
  const parseDMSString = (dmsString) => {
    const match = dmsString.match(/(\d+)\s*deg\s*(\d+)'?\s*([\d.]+)"\s*([NSEW])/i);
    if (match) {
      const [, degrees, minutes, seconds, ref] = match;
      return {
        dms: [Number(degrees), Number(minutes), Number(seconds)],
        ref: ref.toUpperCase(), // Normalize reference to uppercase
      };
    }
    console.error('Invalid DMS string format:', dmsString);
    return { dms: [NaN, NaN, NaN], ref: null };
  };

  // Function to convert DMS to decimal degrees
  const dmsToDecimal = (dms, ref) => {
    let degrees, minutes, seconds;

    if (Array.isArray(dms)) {
      // Handle fraction-based arrays
      degrees = parseFraction(dms[0]);
      minutes = parseFraction(dms[1]) / 60;
      seconds = parseFraction(dms[2]) / 3600;
    } else if (typeof dms === 'string') {
      // Handle string-based DMS
      const dmsf = parseDMSString(dms);
      [degrees, minutes, seconds] = dmsf.dms;
      ref = dmsf.ref;
      minutes /= 60;
      seconds /= 3600;
    } else {
      console.error('Invalid DMS format:', dms);
      return NaN;
    }

    // Compute the decimal degree
    let decimal = degrees + minutes + seconds;

    // Apply the correct sign based on the reference (N/S, E/W)
    if (ref === 'S' || ref === 'W') {
      decimal *= -1;
    }

    return decimal;
  };

  // Convert latitude and longitude
  const lat = dmsToDecimal(dmsLat, latRef);
  const lng = dmsToDecimal(dmsLng, lngRef);

  // Return the converted latitude and longitude
  if (isNaN(lat) || isNaN(lng)) {
    console.error('Conversion failed for coordinates:', dmsLat, latRef, dmsLng, lngRef);
    return null;
  }

  return { latitude: lat, longitude: lng };
}

export const bytesToSize = (input) => {
  const units = ["B", "KB", "MB", "GB", "TB", "PB"];
  const kibibyte = 1024;
  const mebibyte = kibibyte * 1024;
  const gibibyte = mebibyte * 1024;

  // Parse string input like "6.8 MiB"
  const parseFileSizeString = (fileSizeString) => {
    const match = fileSizeString.match(/^([\d.]+)\s*(B|KB|MB|GB|TB|PB|KiB|MiB|GiB|TiB|PiB)$/i);
    if (!match) {
      console.error("Invalid file size format:", fileSizeString);
      return NaN;
    }

    const value = parseFloat(match[1]);
    const unit = match[2].toUpperCase();

    // Convert the value to bytes
    switch (unit) {
      case "B":
        return value;
      case "KB":
      case "KIB":
        return value * kibibyte;
      case "MB":
      case "MIB":
        return value * mebibyte;
      case "GB":
      case "GIB":
        return value * gibibyte;
      default:
        console.error("Unsupported unit:", unit);
        return NaN;
    }
  };

  // If input is a string, parse it
  let bytes = typeof input === "string" ? parseFileSizeString(input) : input;

  // Handle invalid input
  if (isNaN(bytes)) {
    return "Invalid file size";
  }

  // Format the bytes to a readable size
  let i = 0;
  while (bytes >= kibibyte && i < units.length - 1) {
    bytes /= kibibyte;
    i++;
  }

  return bytes.toFixed(2) + " " + units[i];
};

//download image url and filenamee to download image
export const downloadImage = (blob, fileName) => {
  const link = document.createElement("a");
  link.href = blob;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};