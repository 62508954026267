import { createSegmentPlane } from "../Global/functions";
import { COLORS } from "../Global/materials";
import { POLYGONS } from "../Global/variables";
import { sceneManager } from "../SetUps/SceneManager";
import PanelManager from "./PanelManager";
import { panelInSegment } from "./Placement";

export function selectAllPanels(selectedIndices, setSelectedIndices, setShowPanelSettings) {
  const { segmentIndex } = selectedIndices;
  const panels = POLYGONS[segmentIndex].panels;
  let indices = [];
  for (let i = 0; i < panels.length; i++) {
    const panel = panels[i].panel;
    panel.children[0].material.setValues({ opacity: 1, color: COLORS.yellow });
    setShowPanelSettings(true);
    indices.push(i);
  }
  setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: indices });
}

export function selectSinglePanel(panelIndexToAdd, setSelectedIndices, selectedIndices, setShowPanelSettings) {
  const { segmentIndex, panelIndex } = selectedIndices;
  const segment = POLYGONS[segmentIndex] ?? null;
  const panel = segment.panels[panelIndexToAdd].panel ?? null;
  if (panel) {
    panel.children[0].material.setValues({ opacity: 1, color: COLORS.yellow });
    setShowPanelSettings(true);
    if (!panelIndex) { // || !shift (for selecting multipl on shift press??)
      // deselectPanels(panelIndex, setSelectedIndices, selectedIndices); 
      setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: [panelIndexToAdd] });
    } else {
      setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: [...panelIndex, panelIndexToAdd] });
    }
  }
}

export function deselectPanels(indices, setSelectedIndices, selectedIndices) {  
  if (indices) {
    const { segmentIndex, obstacleIndex, panelIndex } = selectedIndices;
    const panels = POLYGONS[segmentIndex].panels ?? null;
    if (panels) {
      for (let index of indices) {
        panels[index].panel.children[0].material.setValues({ opacity: 0, color: COLORS.red });
      }
      const updated = panelIndex.filter(val => !indices.includes(val));
      if (updated.length === 0) {
        setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: obstacleIndex, panelIndex: null });
      } else {
        setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: updated });
      }
    }
  }
}

export function deletePanels(segmentIndex, panelIndices) {
  let selectedSegment = POLYGONS[segmentIndex];
  let scene = sceneManager.scene;
  for (let index of panelIndices) {
    const selectedPanel = selectedSegment.panels[index].panel;
    scene.remove(selectedPanel);
    selectedPanel.geometry.dispose();
    selectedPanel.material.dispose();
  }
  selectedSegment.panels = selectedSegment.panels.filter((_, index) => !panelIndices.includes(index));
}

export function removeExtraPanels(polygons) {
  const allPanels = [];
  let found = false;
  let selected = [];
  sceneManager.scene.getObjectsByProperty("name", "panel", allPanels);
  for (let panel of allPanels) {
    found = false;
    const idToMatch = panel.id;
    selected.push(panel);
    for (let polygon of polygons) {
      const panelList = polygon.panels;
      for (let index of panelList) {
        if (index.panel_id === idToMatch) {
          found = true;
          selected.pop();
          break;
        }
      }
      if (found === true) break;
    }
  }

  for (let i = 0; i < selected.length; i++) {
    sceneManager.scene.remove(selected[i]);
    selected[i].geometry.dispose();
    selected[i].material.dispose();
  }
}

export function removeGrid() {
  const grids = sceneManager.scene.getObjectsByProperty("name", "grid");
  if (grids) {
    for (let grid of grids) {
      grid.removeFromParent();
    grid.geometry.dispose();
    grid.material.dispose();
    }
  }
}

export async function changeOrientation(scene, camera, segmentIndex, solarPanel, setPanels, panelList, panel, panelIndex, deletePanel, setPanelsOK, arePanelsOK) {
  const newPanel = new PanelManager(
    scene,
    camera,
    segmentIndex,
    solarPanel,
    setPanels,
    panelList,
    null,
    null, 
    true
  );
  await newPanel.handleMouseMove(panel.position.project(camera));
  await newPanel.handleMouseMove(newPanel.panel.position.project(camera));
  newPanel.addPanel();

  deletePanels(segmentIndex, [panelIndex]);
  deletePanel([panelIndex]);

  let totalPanels = POLYGONS[segmentIndex].panels.length;
  let current = POLYGONS[segmentIndex].panels[totalPanels - 1].panel;
  if (!panelInSegment(current, segmentIndex)) {
    current.children[0].material.setValues({ opacity: 1, color: COLORS.red });
  } else {
    current.children[0].material.setValues({ opacity: 1, color: COLORS.yellow });
  }
  checkPanelsOK(segmentIndex, setPanelsOK, arePanelsOK);
}


export function checkPanelsOK(segmentIndex, setPanelsOK, arePanelsOK) {
  for(let panel of POLYGONS[segmentIndex].panels) {
    if (panelInSegment(panel.panel, segmentIndex)) {
      setPanelsOK(true);      
    } else {
      setPanelsOK(false);      
      break;
    }
    if(!arePanelsOK) break;
  }
}