import * as THREE from "three";
import { POLYGONS } from "../Global/variables";
import { deselectPanels, selectSinglePanel } from "../Panels/PanelFunctions";
import { selectSegment } from "../Segments/Functions";
import { deselectObstacle, selectObstacle } from "../Obstacles/ObstacleFunctions";

export function selection(selectedIndices, setSelectedIndices, setPolygonDetails, mouse, sceneManager, setShowPanelSettings, setSolarPanel, polygonState) {
  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, sceneManager.camera);

  if (selectedIndices.segmentIndex === null) {
    segmentSelection(selectedIndices, setSelectedIndices, setPolygonDetails, raycaster, setSolarPanel, setShowPanelSettings);
  } else {
    panelSelection(selectedIndices, setSelectedIndices, setShowPanelSettings, raycaster, setSolarPanel, polygonState);
    // obstacleSelection(selectedIndices, setSelectedIndices, raycaster);
    segmentSelection(selectedIndices, setSelectedIndices, setPolygonDetails, raycaster, setSolarPanel, setShowPanelSettings);
  }
}

function segmentSelection(selectedIndices, setSelectedIndices, setPolygonDetails, raycaster, setSolarPanel, setShowPanelSettings) {
  for (let i = 0; i < POLYGONS.length; i++) {
    const intersect = raycaster.intersectObject(POLYGONS[i].shape, false);
    if (intersect.length > 0 && i !== selectedIndices.segmentIndex) {
      setSolarPanel({id: null});
      setShowPanelSettings(false);
      selectSegment(selectedIndices, i, setSelectedIndices, setPolygonDetails);
      break;
    }
  }
}

function panelSelection(selectedIndices, setSelectedIndices, setShowPanelSettings, raycaster, setSolarPanel, polygonsState) {
  const panels = POLYGONS[selectedIndices.segmentIndex].panels.map((pan) => pan.panel);
  let intersect = raycaster.intersectObjects(panels, false);
  if (intersect.length > 0) {
    const panelIndexToAdd = panels.indexOf(intersect[0].object);
    if (selectedIndices.panelIndex?.includes(panelIndexToAdd)) return;
    setSolarPanel({...polygonsState[selectedIndices.segmentIndex].panels[panelIndexToAdd]});
    selectSinglePanel(panelIndexToAdd, setSelectedIndices, selectedIndices, setShowPanelSettings);
  } else {
    setSolarPanel({id: null});
    setShowPanelSettings(false);
    deselectPanels(selectedIndices.panelIndex, setSelectedIndices, selectedIndices);
  }
}

function obstacleSelection(selectedIndices, setSelectedIndices, raycaster) {
  const { segmentIndex, obstacleIndex, panelIndex } = selectedIndices;
  const obstacles = POLYGONS[selectedIndices.segmentIndex].obstructions;
  if (obstacleIndex) {
    deselectObstacle(obstacles[obstacleIndex]);
  }
  for (let i = 0; i < obstacles.length; i++) {
    const intersect = raycaster.intersectObject(obstacles[i].mesh, false);
    if (intersect.length > 0 && i !== selectedIndices.obstacleIndex) {
      selectObstacle(selectedIndices, i, setSelectedIndices);
      return
    }
  }
  setSelectedIndices({ segmentIndex: segmentIndex, obstacleIndex: null, panelIndex: panelIndex });
}

export function panelOrientation(selectedIndices, mouse, sceneManager) {
  const raycaster = new THREE.Raycaster();
  raycaster.setFromCamera(mouse, sceneManager.camera);
  let panelIndex = null;
  if (selectedIndices.segmentIndex !== null) {
    const panels = POLYGONS[selectedIndices.segmentIndex].panels.map((pan) => pan.panel);
    let intersect = raycaster.intersectObjects(panels, false);
    if (intersect.length > 0) {
      panelIndex = panels.indexOf(intersect[0].object);
    }
  }
  return panelIndex;
}