import { atom, selector } from 'recoil';

// Define the atom for POLYGONS array
export const polygonsState = atom({
    key: 'polygonsState',
    default: []
});

export const magicToggleState = atom({
    key: 'magicToggleState',
    default: false
});

export const setBackInput = atom({
    key: 'setBackInput',
    default: {}
});

export const avaArea = atom({
    key: 'avaArea',
    default: ''
});

export const selectedIndicesState = atom({
    key: 'selectedIndicesState',
    default: { segmentIndex: null, obstacleIndex: null, panelIndex: null }
});

export const deleteSegmentIndex = atom({
    key: 'deleteSegmentIndex',
    default: null
});

export const deleteObstacleIndex = atom({
    key: 'deleteObstacleIndex',
    default: null
});

export const deletePanelIndex = atom({
    key: 'deletePanelIndex',
    default: null
});

export const selectedObstacleHeight = atom({
    key: 'selectedObstacleHeight',
    default: 0,
});

export const selectedObstacleOffset = atom({
    key: 'selectedObstacleOffset',
    default: 2
});

export const panelSettings = atom({
    key: 'panelSettings',
    default: false
});

export const solarPanel = atom({
    key: 'solarPanel',
    default: { id: null, orientation: "Portrait", height: 0 }
});

export const panelsOK = atom({
    key: 'panelsOK',
    default: true
});

export const polygonDetailsSelector = selector({
    key: 'polygonDetailsSelector',
    get: ({ get }) => {
        const selectedIndices = get(selectedIndicesState);
        const polygons = get(polygonsState);
        return selectedIndices.segmentIndex !== null ? polygons[selectedIndices.segmentIndex].details : null;
    }
});

