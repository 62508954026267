import * as THREE from 'three';
import { LINES } from '../Global/materials';
import { createEdgesWireframe } from "../Global/functions";

export function createWireframe(panel) {
  const vertices = getVertices(panel);
  const last = vertices.splice(2, 1);
  vertices.push(last[0]);
  const edgePoints = [];
  for (let i = 0; i < vertices.length; i++) {
    let index = (i + 1) % vertices.length;
    const point1 = vertices[i];
    const point2 = vertices[index];

    edgePoints.push(point1);
    edgePoints.push(point2);
  }
  const wireMat = LINES.redMaterial.clone();
  const edgeWireframe = createEdgesWireframe(edgePoints, wireMat);
  edgeWireframe.material.setValues({ transparent: true, opacity: 0 });
  edgeWireframe.renderOrder = 6;
  panel.add(edgeWireframe);
}

export function getVertices(object) {
  const vertices = [];
  const positionAttribute = object.geometry.attributes.position;
  const vertexCount = positionAttribute.count;
  for (let i =0; i < vertexCount; i++) {
    const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
    vertex.applyMatrix4(object.matrixWorld);
    vertices.push(vertex);
  }
  
  return vertices;
}

export function getEdgeDirection(segment, normal) {
  const edgeDirection = new THREE.Vector3();
  const vertices = getVertices(segment);

  for (let i = 0; i < vertices.length; i++) {
    for (let j = i + 1; j < vertices.length; j++) {
      const diff = vertices[i].z - vertices[j].z;
      if (Math.abs(diff) < 1) {
        edgeDirection.subVectors(vertices[i], vertices[j]).normalize();
        const crossProduct = new THREE.Vector3().crossVectors(vertices[i], vertices[j]);
        if (crossProduct.dot(normal) < 0) {
          edgeDirection.negate(); // Reverse direction if counter-clockwise
        }
        return edgeDirection;
      }
    }
  }
  return edgeDirection.normalize();
}

export function getRotation(normal, direction, quaternion) {
  const planeEdgeDirection = new THREE.Vector3(1, 0, 0).applyQuaternion(quaternion);
  const rotationAxis = normal;
  const angle = planeEdgeDirection.angleTo(direction);
  const crossProduct = new THREE.Vector3().crossVectors(planeEdgeDirection, direction);
  const sign = crossProduct.dot(rotationAxis) < 0 ? -1 : 1;
  const rotationQuaternion = new THREE.Quaternion().setFromAxisAngle(rotationAxis, sign * angle);

  return [sign, rotationQuaternion];
}