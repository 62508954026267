import * as THREE from 'three'
import ViewCube from './ViewCube';
import { calculateYDelta, calculateZDelta } from './Helpers';
import { COLORS, FACES } from "../Global/materials";

export default class ViewCubeControls extends THREE.EventDispatcher {
    constructor(camera, cubeSize = 20, domElement) {
        super();
        this.cubeSize = cubeSize;
        this.domElement = domElement;
        this._cube = new ViewCube({
            size: this.cubeSize,
            outline: true,
            bgColor: COLORS.white,
            hoverColor: COLORS.blue,
            outlineColor: COLORS.black
        });
        this._camera = camera;
        this._animation = null;
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this._handleMouseClick = this._handleMouseClick.bind(this);
        this._listen();
    }

    _listen() {
        this.domElement.addEventListener('mousemove', this._handleMouseMove);
        this.domElement.addEventListener('click', this._handleMouseClick);
    }

    _handleMouseClick(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkSideTouch(x, y);
    }

    _checkSideTouch(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._cube.children, true);
        if (intersects.length) {
            // console.log(intersects);
            
            for (let { object } of intersects) {
                if (object.name && object.visible) {
                    if (object.name === FACES.top) {
                        object.visible = false;
                        const threeDFace = this._cube.getObjectByName(FACES.threed);
                        threeDFace.visible = true;
                    }
                    if (object.name === FACES.threed) {
                        object.visible = false;
                        const topFace = this._cube.getObjectByName(FACES.top);
                        topFace.visible = true;
                    }
                    this._rotateTheCube(object.name);
                    break;
                }
            }
        }
    }

    _rotateTheCube(side) {
        switch (side) {
            case FACES.top:
                this._setCubeAngles(Math.PI/2, 0, FACES.top);
                break;
            case FACES.south:
                this._setCubeAngles(0, 0, FACES.south);
                break;
            case FACES.east:
                this._setCubeAngles(0, -Math.PI/2, FACES.east);
                break;
            case FACES.north:
                this._setCubeAngles(0, Math.PI, FACES.north);
                break;
            case FACES.west:
                this._setCubeAngles(0, Math.PI/2, FACES.west);
                break;
            case FACES.threed:
                this._setCubeAngles(Math.PI/4, 0, FACES.threed);
                break;
            default:
                break;
        }
    }

    _setCubeAngles(x, y, face) {
        const base = this._cube.rotation;
        const side = Number(new THREE.Euler().setFromQuaternion(this._cube.quaternion).z.toFixed(0));
        
        if (face === FACES.top || face === FACES.threed) {
            x = calculateZDelta(base.x, x, side);
            y = 0;
        } else {
            x = 0;
            y = calculateYDelta(base.y, y, side);
        }
        this._animation = {
            delta: {
                x: x,
                y: y,
            },
            duration: 500,
            time: Date.now(),
            face: face,
            done: 0
        };
        
    }

    _handleMouseMove(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkSideOver(x, y);
    }

    _checkSideOver(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._cube.children, true);
        // unhover
        this._cube.traverse(function (obj) {
            if (obj.name) {
                obj.material.color.setHex(COLORS.white);
            }
        });
        // check hover
        if (intersects.length) {
            for (let { object } of intersects) {
                if (object.name) {
                    object.parent.children.forEach(function (child) {
                        if (child.name === object.name) {
                            child.material.color.setHex(COLORS.blue);
                        }
                    });
                    break;
                }
            }
        }
    }

    update() {
        this._animate();
    }

    _animate() {
        if (!this._animation) return;
        const now = Date.now();
        const { time, done, duration } = this._animation;
        let chunk = now - time;
        if (chunk + done > duration) chunk = duration - done;
        const angle = this._animateCubeRotation(this._animation, chunk);
        this._animation.done += chunk;
        this._animation.time = now;
        if (done === duration) this._animation = null;
        this.dispatchEvent({
            type: 'angle-change',
            quaternion: this._cube.quaternion.clone(),
            angle: angle,
            face: this._animation ? this._animation.face : null
        });
    }

    _animateCubeRotation({ delta, duration }, time) {
        let ease = 0;
        if (delta.y !== 0) {
            ease = delta.y / duration;
            this._cube.rotateOnAxis(new THREE.Vector3(0, 1, 0), ease * time);            
        } else {
            ease = delta.x / duration;
            this._cube.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), ease * time);
        }
        return ease * time;
    }

    setQuaternion(quaternion) {
        this._cube.setRotationFromQuaternion(quaternion);
    }

    getObject() {
        return this._cube;
    }
};
