import * as THREE from 'three';
import { POLYGONS, VARS } from "../Global/variables";
import SegmentManager from "../Segments/SegmentManager";
import ObstacleManager from "../Obstacles/ObstacleManager";
import { COLORS } from "../Global/materials";
import PanelManager from "../Panels/PanelManager";
import { createSegmentPlane } from "../Global/functions";
import { updateAvaArea, updateSetbackUI } from '../Segments/SetBacks/Functions';

export function loadDrawings(scene, camera, updateSegmentList, updateObstacleList, updatePanelList, panelList, addSetback, setInputValues, setAvailableArea) {
    if (VARS.Current_Project?.model_data && Object.keys(VARS.Current_Project.model_data).length !== 0) {
        let data = JSON.parse(VARS.Current_Project.model_data)

        for (let i = 0; i < data.length; i++) {
            let coordinates = data[i].polygon;
            let segment = new SegmentManager(scene, null, updateSegmentList);
            segment.addShape(coordinates);
            if (data[i].setbacks && data[i].setbacks.length > 0) {
                data[i].setbacks.forEach((sb, index) => {
                    updateSetbackUI(i, setInputValues, sb.value, index);
                });
                addSetback({ setbackAdded: true, segmentIndex: i });
                updateAvaArea(i, setAvailableArea);
            }
            if (data[i].obstruction && data[i].obstruction.length > 0) {
                data[i].obstruction.forEach((obs) => {
                    let obstacle = new ObstacleManager(scene, null, null, null, updateObstacleList, i)

                    if (obs.type === "rectangle") {
                        obstacle.completeShape(obs.polygon, obs.height, obs.offset ? obs.offset : 2, COLORS.maroon, "rectangle")
                    }
                    if (obs.type === "circle") {
                        obstacle.completeShape(obs.polygon, obs.height, obs.offset ? obs.offset : 2, COLORS.maroon, "circle")
                    }
                    obstacle = null;
                })
            }
            if (data[i].panels && data[i].panels.length > 0) {
                data[i].panels.forEach(async function (pan) {
                    try {
                        let solarPanel = { id: pan.id, height: pan.height, tilt: pan.tilt, orientation: pan.orientation };
                        let panel = new PanelManager(scene, camera, i, solarPanel, updatePanelList, panelList, null, pan.position, null);
                        let plane = createSegmentPlane(i);
                        let position = new THREE.Vector3(pan.position.x, pan.position.y, pan.position.z);
                        let _ = await panel.createPanel(position, POLYGONS[i].shape, plane);
                        panel.addPanel();
                        panel = null;
                    }
                    catch (e) {
                        console.error("Error creating panel:", e);
                    }
                })
            }
            segment = null;
        }
    }    
}