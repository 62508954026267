import * as THREE from "three";
import Earcut from "earcut";
import { COLORS, LINES } from "../Global/materials";
import { createSegmentPlane } from "../Global/functions";
import { addExtrude } from "./Modifications";
import { getVertices } from "../Panels/Helpers";

export function createRecGeo(startPoint, endPoint, facetPlane, rayCaster, camera) {
    if (startPoint.x === endPoint.x) {
        endPoint.x += Number.EPSILON;
    }

    if (startPoint.y === endPoint.y) {
        endPoint.y += Number.EPSILON;
    }

    const left = Math.min(startPoint.x, endPoint.x);
    const right = Math.max(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const bottom = Math.max(startPoint.y, endPoint.y);
    const width = right - left;
    const height = bottom - top;

    let dx = startPoint.x - endPoint.x < 0 ? -1 : 1;
    let dy = startPoint.y - endPoint.y < 0 ? -1 : 1;

    // case 1 -x,+y, +x,-y
    let _vecTopLeft = new THREE.Vector3(startPoint.x, startPoint.y, 0);
    let _vecDownRight = new THREE.Vector3(endPoint.x, endPoint.y, 0);
    let _vecTopRight = new THREE.Vector3(
        startPoint.x - dx * width,
        startPoint.y,
        0
    );
    let _vecDownLeft = new THREE.Vector3(
        startPoint.x,
        startPoint.y - dy * height,
        0
    );

    if (dy === dx) {
        _vecTopLeft.set(startPoint.x, startPoint.y - dy * height, 0);
        _vecDownRight.set(startPoint.x - dx * width, startPoint.y, 0);
        _vecTopRight.set(endPoint.x, endPoint.y, 0);
        _vecDownLeft.set(startPoint.x, startPoint.y, 0);
    }

    // let worldStartPoint = _vecTopLeft.clone().applyMatrix4(camera.matrixWorldInverse);

    let topleft = new THREE.Vector3();
    let topright = new THREE.Vector3();
    let bottomleft = new THREE.Vector3();
    let bottomright = new THREE.Vector3();

    rayCaster.setFromCamera(_vecTopLeft, camera);
    rayCaster.ray.intersectPlane(facetPlane, topleft);
    rayCaster.setFromCamera(_vecTopRight, camera);
    rayCaster.ray.intersectPlane(facetPlane, topright);
    rayCaster.setFromCamera(_vecDownLeft, camera);
    rayCaster.ray.intersectPlane(facetPlane, bottomleft);
    rayCaster.setFromCamera(_vecDownRight, camera);
    rayCaster.ray.intersectPlane(facetPlane, bottomright);
    let points = [topleft, topright, bottomright, bottomleft, topleft];
    let g = new THREE.BufferGeometry().setFromPoints([
        topleft,
        topright,
        bottomright,
        bottomleft,
        topleft,
    ]);

    return { points, g }
}

export function createCirGeo(startPoint, endPoint, facetPlane, rayCaster, camera) {
    const points = [];
    let centerX = startPoint.x;
    let centerY = startPoint.y;
    const radius = startPoint.distanceTo(endPoint)

    const deltaX = endPoint.x - centerX;
    const deltaY = endPoint.y - centerY;
    let dynamicRadius = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    dynamicRadius += radius;

    const aspectRatio = camera.aspect;
    let segments = 32;
    for (let i = 0; i <= segments; i++) {
        const theta = (i / segments) * Math.PI * 2;
        const adjustedRadius = dynamicRadius / aspectRatio;
        const x = centerX + adjustedRadius * Math.cos(theta);
        const y = centerY + dynamicRadius * Math.sin(theta);

        let temp = new THREE.Vector3(x, y, 0)

        let newpoint = new THREE.Vector3()
        rayCaster.setFromCamera(temp, camera);
        rayCaster.ray.intersectPlane(facetPlane, newpoint);

        points.push(newpoint);
    }
    points.push(points[0]);

    let geo = new THREE.BufferGeometry().setFromPoints(points);
    return { points, geo };

}

function findCenter(mesh) {
    const bbox = new THREE.Box3().setFromObject(mesh);
    const center = new THREE.Vector3();
    bbox.getCenter(center);

    return center;
}

function getRadius(center, point) {
    const p = new THREE.Vector3(point.x, point.y, point.z);
    const radius = center.distanceTo(p);
    return radius;
}

export function addOffset(mesh, points, type, shape, offset, height, segmentIndex) {
    const plane = createSegmentPlane(segmentIndex);
    let offsetGeo = new THREE.BufferGeometry();
    let center = null;
    let tempExtrude = null;
    let radius = null;
    
    switch (type) {
        case "circle":
            tempExtrude = addExtrude(points, shape, 0, COLORS.maroon, mesh.parent);
            center = findCenter(tempExtrude);
            const obsRadius = getRadius(center, points[0]);
            radius = obsRadius;
            offsetGeo = new THREE.CircleGeometry(obsRadius + (offset * 0.0254));
            tempExtrude.removeFromParent();
            tempExtrude.geometry.dispose();
            tempExtrude.material.dispose();
            break;

        case "rectangle":
            tempExtrude = addExtrude(points, shape, 0, COLORS.maroon, mesh.parent);
            center = findCenter(tempExtrude);

            const p1 = new THREE.Vector3(points[0].x, points[0].y, points[0].z);
            const p2 = new THREE.Vector3(points[1].x, points[1].y, points[1].z);
            const p3 = new THREE.Vector3(points[3].x, points[3].y, points[3].z);
            const x = new THREE.Vector3().subVectors(p2, p1).normalize().multiplyScalar(offset * 0.0254);
            const y = new THREE.Vector3().subVectors(p1, p3).normalize().multiplyScalar(offset * 0.0254);

            const directions = points.map((point) => {
                const corner = new THREE.Vector3(point.x, point.y, point.z);
                const direction = new THREE.Vector3().subVectors(corner, center);
                return direction;
            });

            const addOffsets = [[x.clone().negate(), y], [x, y], [x, y.clone().negate()], [x.clone().negate(), y.clone().negate()], [x.clone().negate(), y]];
            let corners = directions.map((direction, index) => {
                direction.add(addOffsets[index][0]);
                direction.add(addOffsets[index][1]);
                return tempExtrude.worldToLocal(direction);
            });
            corners.pop();
            
            const verticesFlat = corners.reduce((acc, vertex) => acc.concat([vertex.x, vertex.y, vertex.z]), []);
            const earCutData = Earcut(verticesFlat, null, 3);
            const indices = new Uint32Array(earCutData);
            offsetGeo.setIndex(new THREE.BufferAttribute(indices, 1));
            offsetGeo.setAttribute('position', new THREE.BufferAttribute(new Float32Array(verticesFlat), 3));

            tempExtrude.removeFromParent();
            tempExtrude.geometry.dispose();
            tempExtrude.material.dispose();

            break;
    }

    const wireframe = new THREE.EdgesGeometry(offsetGeo);
    const newOffset = new THREE.LineSegments(wireframe, LINES.orangeMaterial);
    newOffset.position.set(center.x, center.y, center.z);

    newOffset.raycast = () => { };

    newOffset.material.transparent = true;
    newOffset.material.opacity = 1;
    newOffset.renderOrder = 1;
    newOffset.material.depthTest = false;
    newOffset.name = "offset";

    mesh.add(newOffset);

    if (radius != null) {
        const quaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, 1), plane.normal);
        newOffset.setRotationFromQuaternion(quaternion);

        const geo = new THREE.CircleGeometry(radius);
        const wf = new THREE.EdgesGeometry(geo);
        const temp = new THREE.LineSegments(wf, LINES.orangeMaterial);
        temp.position.set(center.x, center.y, center.z);

        const quat = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, 1), plane.normal);
        temp.setRotationFromQuaternion(quat);

        const vecs = getVertices(temp);
        const updarr = [];
        for (let i = 0; i < vecs.length; i += 2) {
            const vec = newOffset.localToWorld(vecs[i]);
            updarr.push(vec);
        }
        updarr.push(updarr[0]);

        const shapePoints = updarr.map(point => new THREE.Vector2(point.x, point.y));
        const newShape = new THREE.Shape(shapePoints);
        const extrudeMesh = addExtrude(updarr, newShape, height, COLORS.maroon, mesh.parent);

        newOffset.removeFromParent();
        temp.material.dispose();
        temp.geometry.dispose();
        mesh.removeFromParent();
        mesh.geometry.dispose();
        mesh.material.dispose();

        extrudeMesh.add(newOffset);

        return { mesh: extrudeMesh, shape: newShape, points: updarr };
    }

    return { mesh: mesh, shape: shape, points: points };
}

