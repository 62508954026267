
import { bytesToSize, convertDMSToDD } from './CONSTANT';

export function getExifData(exifData) {
    
    const getLatLng = () => {
      console.log("consoling exif data",exifData);
      const gps = exifData.exif?.GPS || exifData.exif || {};
      const latitude = gps.GPSLatitude ;
      const longitude = gps.GPSLongitude;
      console.log("consoling lat and long",latitude,longitude);
  
      if (latitude && longitude) {
        const { latitude: lat, longitude: lng } = convertDMSToDD(latitude, gps.GPSLatitudeRef, longitude, gps.GPSLongitudeRef);
        return { latitude: lat, longitude: lng };
        console.log("consoling lat and long",lat,lng);
      }
      return null;
    };
  
    const getDateTimeOriginal = () => {
      if (exifData.exif && exifData.exif.DateTimeOriginal) {
        return exifData.exif.DateTimeOriginal;
      } else if (exifData.exif.EXIF && exifData.exif.EXIF.DateTimeOriginal) {
        return exifData.exif.EXIF.DateTimeOriginal;
      }
      return null;
    };
  
    const getCameraMake = () => {
      if (exifData.exif && exifData.exif.Make) {
        return exifData.exif.Make;
      } else if (exifData.exif.IFD0 && exifData.exif.IFD0.Make) {
        return exifData.exif.IFD0.Make;
      }
      return null;
    };
  
    const getCameraModel = () => {
      if (exifData.exif && exifData.exif.Model) {
        return exifData.exif.Model;
      } else if (exifData.exif.IFD0 && exifData.exif.IFD0.Model) {
        return exifData.exif.IFD0.Model;
      }
      return null;
    };
  
    const getMimeType = () => {
      if (exifData.exif && exifData.exif.MIMEType) {
        return exifData.exif.MIMEType;
      } else if (exifData.exif.FILE.MimeType && exifData.exif.FILE.MimeType) {
        return exifData.exif.FILE.MimeType;
      }
      return null;
    };
  
    const getImageWidth = () => {
      if (exifData.exif && exifData.exif.ImageWidth) {
        return exifData.exif.ImageWidth;
      } else if (exifData.exif.COMPUTED && exifData.exif.COMPUTED.Width) {
        return exifData.exif.COMPUTED.Width;
      }
      return null;
    };

    const getHeight = () => {
        if (exifData.exif && exifData.exif.ImageHeight) {
          return exifData.exif.ImageHeight;
        } else if (exifData.exif.COMPUTED && exifData.exif.COMPUTED.Height) {
          return exifData.exif.COMPUTED.Height;
        }
        return null;
      };
    
      const getFileSize = () => {
        if (exifData.exif && exifData.exif.FileSize ) {
          return bytesToSize(exifData.exif.FileSize);
        } else if (exifData.exif.FILE && exifData.exif.FILE.FileSize) {
          return bytesToSize(exifData.exif.FILE.FileSize);
        }
        return null;
      };
      const latLng = getLatLng();
    return {
      latitude: latLng?.latitude,
      longitude: latLng?.longitude,
      dateTimeOriginal: getDateTimeOriginal(),
      cameraMake: getCameraMake(),
      cameraModel: getCameraModel(),
      mimeType: getMimeType(),
      imageWidth: getImageWidth(),
      height: getHeight(),
      fileSize: getFileSize(),
    };
}