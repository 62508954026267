import { Button, Flex, Image, Layout, Typography } from 'antd'
import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import eagle from '../assets/eagle.svg'
import light_svg from '../assets/notification-light.svg'
import dark_svg from '../assets/notification-dark.svg'
import { Link } from 'react-router-dom'

const AppHeader = ({ theme, handleThemeChange, currentProject }) => {
  // console.log("Current PRoject", currentProject)
  return (
    <Layout.Header style={{ borderBottom: `1px solid ${!theme ? '#e1e1e1' : '#525252'}` }}>
      <Flex justify="space-between" align="center" wrap="wrap">
        <Flex gap={'large'} align="center">
          <Link to="https://app.eagleyedrone.com/projects">
            <Button type="default" size="small" style={{marginTop:'7px'}} icon={<IoIosArrowBack size={20} style={{ marginTop: '' }} />} />
          </Link>

          <Image src={eagle} preview={false} width={25} className="image-style" />
          <Typography.Text strong className={`${!theme ? 'text-black' : 'text-light'} my-0`}>
            {currentProject?.name} - {currentProject?.address}
          </Typography.Text>
        </Flex>
        <Flex align="center" gap={'large'}>
          <Typography.Text strong className={`${!theme ? 'text-gray' : 'text-light'} my-0`}>
            Eagle Eye 3D Design Tool{' '}
            {/* <span className={`${!theme ? "text-blue" : "text-light"}`}>
              / {currentDesign?.name}
            </span> */}
          </Typography.Text>
          <Typography.Link onClick={handleThemeChange}>
            <Image width={28} preview={false} src={theme ? dark_svg : light_svg} alt="light-dark" />
          </Typography.Link>
        </Flex>
      </Flex>
    </Layout.Header>
  )
}

export default AppHeader
