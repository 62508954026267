import { POLYGONS } from "../../Global/variables";
import { getPolyArea } from "../AreaPitchAzimu";
import { addSetbackToEdge, updateWireframe } from "./Setbacks";
import { sceneManager } from "../../SetUps/SceneManager";

export function updateSetbackUI(segIndex, setInputValues, inputValue, edgeIndex) {
    POLYGONS[segIndex].setBacks.isSet = true;
    let setbacks = POLYGONS[segIndex].setBacks.edgesData;

    const updateSetback = (index) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: inputValue
        }));
        addSetbackToEdge(setbacks, index, inputValue, segIndex);
        setbacks[index].value = inputValue;
    };

    if (edgeIndex !== undefined) {
        updateSetback(Number(edgeIndex));
    } else {
        setbacks.forEach((edge, index) => {
            updateSetback(index);
        });
        if (inputValue == 0) {
            const edges = POLYGONS[segIndex].edges;
            setbacks.forEach((edge, index) => {
                edge.point1.copy(edges[index].point1);
                edge.point2.copy(edges[index].point2);
            })
            updateWireframe(edges, segIndex);
            POLYGONS[segIndex].setBacks.mesh.material.setValues({ color: 0x800080 });
            POLYGONS[segIndex].setBacks.mesh.material.needsUpdate = true;
        }
    }
}

export function updateAvaArea(index, setAvailableArea) {
    let area = getPolyArea(POLYGONS[index].setBacks.edgesData).toFixed(2);
    POLYGONS[index].setBacks.avaArea = area;
    setAvailableArea(area);
}

export function deleteSetback(segmentIndex) {
    let selectedSeg = POLYGONS[segmentIndex]
    let edges = selectedSeg.edges;
    let data = []
    edges.forEach(edge => {
        const setback = {
            vertexId1: edge.vertexId1,
            vertexId2: edge.vertexId2,
            value: 0,
            point1: edge.point1.clone(),
            point2: edge.point2.clone()
        };
        data.push(setback);
    })
    let setback = selectedSeg.setBacks;
    removeMesh(setback);
    selectedSeg.setBacks.edgesData = data;
    selectedSeg.setBacks.isSet = false;

}

export function removeMesh(setback) {
    sceneManager.scene.remove(setback.mesh);
    setback.mesh.geometry.dispose();
    setback.mesh.material.dispose();
    setback.mesh = null;
}