import * as THREE from 'three';
import { createSegmentPlane } from "../Global/functions";
import { POLYGONS } from "../Global/variables";
import { gridHelper, panelInSegment } from './Placement';
import { createWireframe, getEdgeDirection, getRotation } from './Helpers';
import { COLORS } from '../Global/materials';
import { checkPanelsOK } from './PanelFunctions';
import { degToRad } from 'three/src/math/MathUtils.js';

class PanelManager {
  constructor(scene, camera, segmentIndex, solarPanel, updatePanelList, panelList, panel, position, selected) {
    this.scene = scene;
    this.camera = camera;
    this.segmentIndex = segmentIndex;
    this.solarPanel = solarPanel;
    this.updatePanelList = updatePanelList;
    this.panelList = panelList;
    this.panel = panel;
    this.position = position;
    this.selected = selected;
    this.area = 0;
    this.offset = 0;
    this.plane = null;
    this.grid = null;
    this.first = null;
  }

  handleMouseDown(setPanelsOK) {
    if (panelInSegment(this.panel, this.segmentIndex)) {
      this.panel.children[0].material.setValues({ opacity: 0, color: COLORS.red });
      this.addPanel();
    }
  }

  async handleMouseMove(mouse) {
    await this.getPlaneIntersect(mouse);
  }

  handleMouseUp(setPanelsOK, arePanelsOK) {
    if (!panelInSegment(this.panel, this.segmentIndex)) {
      this.panel.position.copy(this.position);
    }
    checkPanelsOK(this.segmentIndex, setPanelsOK, arePanelsOK);
  }

  async getPlaneIntersect(mouse) {
    // Create a raycaster and update the raycaster with the camera and mouse coordinates
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);

    // Create the plane from the segment
    this.plane = createSegmentPlane(this.segmentIndex);
    const segment = POLYGONS[this.segmentIndex].shape;

    // Calculate the intersection point with the plane
    const intersection = new THREE.Vector3();
    raycaster.ray.intersectPlane(this.plane, intersection);

    if (!this.panel) {
      let _ = await this.createPanel(intersection, segment, this.plane);
    } else {
      const orientation = this.plane.normal.clone().normalize();
      this.updatePanelPosition(intersection, orientation);
    }
  }

  createPanel(mousePosition, segment, plane) {
    return new Promise((resolve, reject) => {
      this.plane = plane;
      let w, width = 0;
      let h, height = 0;

      const data = this.panelList.filter((panel) => panel.id === this.solarPanel.id)[0];      
      if (data.unit === 'Metric') {
        // if in mm
        w = data.width / 1000;
        h = data.height / 1000;
        this.area = data.width * data.height / 92900;
      }
      else if (data.unit === 'Imperial') {
        // if in inches
        w = data.width / 39.37;
        h = data.height / 39.37;
        this.area = data.width * data.height / 144;
      }
      if (this.solarPanel.orientation === "Portrait") {
        width = w;
        height = h;
      } else {
        width = h;
        height = w;
      }

      const geometry = new THREE.PlaneGeometry(width, height);
      const normal = new THREE.Vector3(0, 0, 1);
      const orientation = this.plane.normal.clone().normalize();
      if (orientation.z < 0) {
        orientation.negate();
      }

      const direction = getEdgeDirection(segment, orientation);
      const max = new THREE.Vector3(orientation.x, orientation.y, 0).normalize();
      this.offset = ((height / 2 * 39.37) * Math.sin(degToRad(Number(this.solarPanel.tilt) - POLYGONS[this.segmentIndex].details.pitch))) + this.solarPanel.height;            

      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(
        sessionStorage.getItem(data.id.toString()),
        (texture) => {
          // Set texture wrapping and repeat if necessary
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
          texture.repeat.set(1, 1); // Adjust this if you want to tile the texture

          if (this.solarPanel.orientation === "Landscape") {
            texture.rotation = Math.PI / 2;
          }

          if (!this.panel) {
            // If the panel does not exist, create it with the texture
            const material = new THREE.MeshStandardMaterial({ map: texture, roughness: 0.8, side: THREE.DoubleSide, depthTest: false, depthWrite: false, transparent: true });
            this.panel = new THREE.Mesh(geometry, material);
            this.panel.name = "panel";
            this.panel.renderOrder = 5;
            this.panel.frustumCulled = false;
            if (this.solarPanel.orientation === "Portrait"){
              this.panel.panelType = 'potrait'
            }else{
              this.panel.panelType = 'landscape'
            }

            let quaternion = new THREE.Quaternion();
            
            if (this.solarPanel.tilt) {
              const temp = new THREE.Quaternion().setFromUnitVectors(normal, max);  
              quaternion.rotateTowards(temp, degToRad(Number(this.solarPanel.tilt)));      
            } else {
              quaternion.setFromUnitVectors(normal, orientation);
            }
            this.panel.applyQuaternion(quaternion);
            const [sign, rotation] = getRotation(orientation, direction, quaternion);
            this.panel.applyQuaternion(rotation);
            this.offset *= sign;
            this.panel.position.copy(mousePosition.clone().add(orientation.clone().multiplyScalar(this.offset / 39.37)));
            createWireframe(this.panel);
            this.scene.add(this.panel);
            resolve();
          } else {this.updatePanelPosition(mousePosition, orientation)}
        },
        undefined,
        (error) => {
          console.error('Error loading texture:', error);
          reject();
        }
      );
    })
  }

  updatePanelPosition(mousePosition, orientation) {
    if (this.offset === 0) {
      this.offset = this.solarPanel.offset;
    }
    this.panel.position.copy(mousePosition.clone().add(orientation.clone().multiplyScalar(this.offset / 39.37)));
    if (!panelInSegment(this.panel, this.segmentIndex)) {
      this.panel.children[0].material.setValues({ opacity: 1, color: COLORS.red });
    }
    else {
      if (this.selected) {
        this.panel.children[0].material.setValues({ opacity: 1, color: COLORS.yellow });
      } else {
        this.panel.children[0].material.setValues({ opacity: 0 });
      }
    }
    if (POLYGONS[this.segmentIndex].panels.length > 0) {
      if (this.grid) {
        this.first.remove(this.grid);
        this.grid.geometry.dispose();
        this.grid.geometry.dispose();
      }
      let position = null;
      [this.grid, position, this.first] = gridHelper(this.panel, POLYGONS[this.segmentIndex].panels);
      if(this.grid){
        this.first.add(this.grid);
      }
      this.panel.position.copy(position);
    }
  }

  addPanel() {
    POLYGONS[this.segmentIndex].panels.push({panel: this.panel, area: this.area});
    if (this.grid) {
      this.first.remove(this.grid);
      this.grid.geometry.dispose();
      this.grid.geometry.dispose();
      this.grid = null;
    }
    let uiData = { ...this.solarPanel, panel_id: this.panel.id, offset: this.offset };    
    this.updatePanelList({ newPanel: uiData, segmentIndex: this.segmentIndex });
    this.panel = null;
    this.offset = 0;
  }
}

export default PanelManager;