import * as THREE from 'three'

export const FACES = {
    top: 1,
    north: 2,
    south: 3,
    west: 4, 
    east: 5, 
    threed: 6
};

export const COLORS = {
    green: 0x00ff00,
    red: 0xff0000,
    purple: 0x800080,
    orange: 0xffa500,
    yellow: 0xffff00,
    magenta: 0x00ff00,
    maroon: 0x800000,
    white: 0xffffff,
    black: 0x000000,
    blue: 0x9fd5dd,
    setback : 0xda5e80,


};
export const LINES = {
    purpleMaterial: new THREE.LineBasicMaterial({
        color: 0x800080,
        depthTest: false,
    }),
    greenMaterial: new THREE.LineBasicMaterial({
        color: 0x00ff00,
        depthTest: false,
    }),
    redMaterial: new THREE.LineBasicMaterial({
        color: COLORS.red,
        depthTest: false,
    }),
    drawingLineMaterial: new THREE.LineBasicMaterial({
        color: COLORS.red,
        transparent: true,
        depthTest: false,
    }),
    magentaMaterial: new THREE.LineBasicMaterial({
        color: COLORS.magenta,
        depthTest: false,
    }),
    orangeMaterial: new THREE.LineBasicMaterial({
        color: COLORS.orange,
        depthTest: false,
    }),
    referenceLine: new THREE.LineSegments(
        new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(10, 0, 0),
            new THREE.Vector3(0, 10, 0),
        ]),
        new THREE.LineDashedMaterial({
            color: 0xffffff,
            dashSize: 0.3,
            gapSize: 0.2,
            transparent: true,
            depthTest: false,
        })
    ),
};
export const GEOMETRIES = {
    polygonVertex: new THREE.CircleGeometry(0.1, 32),
    obstructionBox: new THREE.Line(new THREE.BufferGeometry(), LINES.greenMaterial),
    obstructionCircle: new THREE.Line(new THREE.BufferGeometry(), LINES.redMaterial),
}
export const MATERIALS = {
    polygon: new THREE.MeshBasicMaterial({
        color: "white",
        side: THREE.DoubleSide,
        transparent: true,
        depthTest: false,
        depthWrite: false,
        opacity: 0.05
    }),

    polygonWireframe: new THREE.LineBasicMaterial({
        color: COLORS.purple,
        depthTest: false,
    }),

    setbackWireframe :  new THREE.LineBasicMaterial({
        color: COLORS.setback,
        depthTest: false,
    }),

    polygonVertex: new THREE.MeshBasicMaterial({
        color: COLORS.yellow,
        transparent: true,
        depthTest: false,
        opacity: 0
    }),

}
export const OBSTACLE = {
    box: new THREE.Line(new THREE.BufferGeometry(), LINES.greenMaterial),
    circle: new THREE.Line(new THREE.BufferGeometry(), LINES.redMaterial),
}