import * as THREE from 'three'
import CompassRing from './CompassRing';
import { calculateDelta } from './Helpers';
import {FACES} from "../Global/materials";

export default class CompassRingController extends THREE.EventDispatcher {
    constructor(camera, domElement) {
        super();
        this._camera = camera;
        this.domElement = domElement;
        this._compass = new CompassRing();
        this._animation = null;
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this._handleMouseClick = this._handleMouseClick.bind(this);
        this._listen();
    }
    _listen() {
        this.domElement.addEventListener('mousemove', this._handleMouseMove);
        this.domElement.addEventListener('click', this._handleMouseClick);
    }
    _handleMouseMove(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkSideOver(x, y);
    }

    _checkSideOver(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._compass.children, true);
        // unhover
        this._compass.traverse(function (obj) {
            if (obj.name) {
                obj.material.color.setHex(obj.userData.color);
            }
        });
        // check hover
        if (intersects.length) {
            for (let { object } of intersects) {
                if (object.name) {
                    object.parent.children.forEach(function (child) {
                        if (child.name === object.name) {
                            child.material.color.setHex(child.userData.hover);
                        }
                    });
                    break;
                }
            }
        }
    }
    _handleMouseClick(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkSideTouch(x, y);
    }
    _checkSideTouch(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._compass.children, true);
        if (intersects.length) {
            for (let { object } of intersects) {
                if (object.name) {
                    this._rotateCompass(object.name);
                    break;
                }
            }
        }
    }
    _rotateCompass(side) {
        switch (side) {
            case FACES.south:
                this._setCompassAngles(0, FACES.south);
                break;
            case FACES.east:
                this._setCompassAngles(Math.PI*1.5, FACES.east);
                break;
            case FACES.north:
                this._setCompassAngles(Math.PI, FACES.north);
                break;
            case FACES.west:
                this._setCompassAngles(Math.PI/2, FACES.west);
                break;
            default:
                break;
        }
    }

    _setCompassAngles(angle, face) {
        const base = this._compass.rotation;
        this._animation = {
            delta: calculateDelta(base.z, angle),
            duration: 500,
            time: Date.now(),
            face: face,
            done: 0
        };
    }

    update() {
        this._animate();
    }

    _animate() {
        if (!this._animation) return;
        const now = Date.now();
        const { time, done, duration } = this._animation;
        let chunk = now - time;        
        if (chunk + done > duration) chunk = duration - done;
        const angle = this._animateCompassRotation(this._animation, chunk);
        this._animation.done += chunk;
        this._animation.time = now;
        if (done === duration) this._animation = null;
        this.dispatchEvent({
            type: 'compass-angle-change',
            face: this._animation ? this._animation.face : null,
            angle: angle
        });
    }
    _animateCompassRotation({delta, duration}, time) {
        const ease = delta / duration;
        this._compass.rotateOnAxis(new THREE.Vector3(0, 0, 1), ease * time); 
        return ease * time;
    }

    getObject() {
        return this._compass;
    }
}