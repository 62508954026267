import * as THREE from 'three';
import ZoomButtons from './ZoomButtons';

export default class ZoomButtonsControls extends THREE.EventDispatcher {
    constructor(camera, domElement, controls) {
        super();
        this._camera = camera;
        this.domElement = domElement;
        this._controls = controls;
        this._buttons = new ZoomButtons();
        this._handleMouseMove = this._handleMouseMove.bind(this);
        this._handleMouseClick = this._handleMouseClick.bind(this);
        this._listen();
    }

    _listen() {
        this.domElement.addEventListener('mousemove', this._handleMouseMove);
        this.domElement.addEventListener('click', this._handleMouseClick);
    }

    _handleMouseMove(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkButtonOver(x, y);
    }

    _checkButtonOver(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._buttons.children, true);
        // unhover
        this._buttons.traverse(function (obj) {
            if (obj.name) {
                obj.material.color.setHex(0xffffff);
            }
        });
        // check hover
        if (intersects.length) {
            for (let { object } of intersects) {
                if (object.name) {
                    object.parent.children.forEach(function (child) {
                        if (child.name === object.name) {
                            child.material.color.setHex(0xe2e2e2);
                        }
                    });
                    break;
                }
            }
        }
    }

    _handleMouseClick(event) {
        const x = (event.offsetX / event.target.clientWidth) * 2 - 1;
        const y = -(event.offsetY / event.target.clientHeight) * 2 + 1;
        this._checkButtonTouch(x, y);
    }

    _checkButtonTouch(x, y) {
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera({ x, y }, this._camera);
        const intersects = raycaster.intersectObjects(this._buttons.children, true);
        if (intersects.length) {
            for (let { object } of intersects) {
                if (object.name === "in") {
                    this._controls.zoomIn();
                } else if (object.name === "out") {
                    this._controls.zoomOut();
                }
            }
        }
    }

    getObject() {
        return this._buttons;
    }
}