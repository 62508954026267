import * as THREE from 'three';
import { POLYGONS } from "../Global/variables";
import { getVertices } from './Helpers';
import { earClipping, createSegmentPlane } from '../Global/functions';
import { sceneManager } from '../SetUps/SceneManager';

const OFFSET = 1/39.37;
let snapThreshold = 0.15;

export function panelInSegment(panel, index) {
  const plane = createSegmentPlane(index);
  const mesh = setbackSegment(index);
  const raycaster = new THREE.Raycaster();
  const normal = plane.normal.clone().normalize();

  const vertices = getVertices(panel);

  const projected = vertices.map(vertex => {      
    raycaster.set(vertex, normal);
    const intersects = raycaster.intersectObject(mesh, false);
    raycaster.set(vertex, normal.negate());
    const intersects2 = raycaster.intersectObject(mesh, false);
    if (intersects.length > 0 || intersects2.length > 0){
      return true;
    }
    return false
  })
  removeMesh(mesh);

  return projected.every(point => point) && !panelOverlap(raycaster, normal, [...vertices, panel.position.clone()], index, panel) && !obstacleOverlap(raycaster, normal, index, panel);
}

const setbackSegment = (index) => {
  const vertices = POLYGONS[index].setBacks.edgesData.map((edge) => edge.point1);
  const mesh = earClipping(vertices);
  sceneManager.scene.add(mesh);
  return mesh;
}

const removeMesh = (mesh) => {
  sceneManager.scene.remove(mesh);
  mesh.geometry.dispose();
  mesh.material.dispose();
}

const panelOverlap = (raycaster, normal, vertices, index, panel) => {  
  const existingPanels = POLYGONS[index].panels.map(panel => panel.panel.clone());
  const cut = POLYGONS[index].panels.map(pan => pan.panel).indexOf(panel);
  if (cut > -1) {
    existingPanels.splice(cut, 1);
  }  
  for (let i=0; i < vertices.length; i++) {
    const point = vertices[i].clone().add(normal.clone().multiplyScalar(-2));
    raycaster.set(point, normal);
    const intersects = raycaster.intersectObjects(existingPanels, false);    
    if (intersects.length > 0) return true;
  }
  for (let p of existingPanels) {
    const pves = getVertices(p);
    pves.push(p.position.clone());
    for (let j=0; j < pves.length; j++) {
      const point = pves[j].clone().add(normal.clone().multiplyScalar(-2));
      raycaster.set(point, normal);
      const intersects = raycaster.intersectObject(panel, false);
      if (intersects.length > 0) return true;
    }
  }
  return false;
}

const obstacleOverlap = (raycaster, normal, index, panel) => {
  const obstructions = POLYGONS[index].obstructions;
  const shapes = obstructions.map((obs) => {return obs.mesh.getObjectByName('offset')});
  const points = shapes.map((shape) => {return getVertices(shape)});
  for (let point of points) {
    for (let p=0; p < point.length; p++) {
      raycaster.set(point[p], normal.negate());
      const intersects = raycaster.intersectObject(panel, false);
      if (intersects.length > 0) return true;
    }
  }
  return false;
}

export function  gridHelper(panel, panels, mouse) {
  if (panels.length === 0) return [null, null, null];

  const bestPanel = findBestPanel(panel, panels);

  if (!bestPanel.panel) return [null, null, null];

  const panelWorldPos = panel.position.clone();
  const panelLocalPos = bestPanel.panel.worldToLocal(panelWorldPos.clone());

  const snappedWorldPos = snapToGrid(panel, bestPanel.panel, bestPanel.cellW, bestPanel.cellH, mouse);
  const snappedLocalPos = bestPanel.panel.worldToLocal(snappedWorldPos.clone());
  const snapDeltaTolerance = 0.001;

  // Adjust threshold based on panel type
  const baseThreshold = 0.1; // Equal threshold for movement
  const snapThresholdX = panel.panelType === 'portrait' ? baseThreshold * (panel.geometry.parameters.width / panel.geometry.parameters.height) : baseThreshold;
  const snapThresholdY = panel.panelType === 'landscape' ? baseThreshold * (panel.geometry.parameters.height / panel.geometry.parameters.width) : baseThreshold;

  const horizontalSnap = Math.abs(snappedLocalPos.x - panelLocalPos.x) > snapDeltaTolerance;
  const verticalSnap = Math.abs(snappedLocalPos.y - panelLocalPos.y) > snapDeltaTolerance;

  const activateColumn = horizontalSnap;
  const activateRow = verticalSnap;

  // Snap panel position if within adjusted threshold
  if (bestPanel.dx <= snapThresholdX) panel.position.x = bestPanel.col * bestPanel.cellW;
  if (bestPanel.dy <= snapThresholdY) panel.position.y = bestPanel.row * bestPanel.cellH;

  let positions = gridCalculations(activateColumn, activateRow, bestPanel);
  let grid = gridGeometry(positions);

  return [grid, snappedWorldPos, bestPanel.panel];
}

function findBestPanel(panel, panels) {
  if (!panels.length) return null;

  let bestCandidate = null;
  let bestMetric = Infinity;

  // Iterate over all candidate panels.
  for (let cand of panels) {
    if (cand.panel.id === panel.id) continue;

    const candPanel = cand.panel;
    const { width, height } = candPanel.geometry.parameters;
    const cellW = width + OFFSET;
    const cellH = height + OFFSET;

    // Get world positions and convert the moving panel’s position into candidate–local space.
    const worldCand = new THREE.Vector3();
    const worldPanel = new THREE.Vector3();
    candPanel.getWorldPosition(worldCand);
    panel.getWorldPosition(worldPanel);

    const relPos = new THREE.Vector3().subVectors(worldPanel, worldCand);
    const invQuat = candPanel.quaternion.clone().invert();
    relPos.applyQuaternion(invQuat);

    // Determine the candidate’s grid cell indices.
    const col = Math.round(relPos.x / cellW);
    const row = Math.round(relPos.y / cellH);
    const gridCenterX = col * cellW;
    const gridCenterY = row * cellH;

    // Compute candidate grid boundaries.
    const candidateLeft = gridCenterX - cellW / 2;
    const candidateRight = gridCenterX + cellW / 2;
    const candidateBottom = gridCenterY - cellH / 2;
    const candidateTop = gridCenterY + cellH / 2;

    // Get the moving panel’s half dimensions.
    const panelWidth = panel.geometry.parameters.width;
    const panelHeight = panel.geometry.parameters.height;
    const panelHalfWidth = panelWidth / 2;
    const panelHalfHeight = panelHeight / 2;

    // Compute the moving panel’s edges in candidate local space.
    const panelLeft = relPos.x - panelHalfWidth;
    const panelRight = relPos.x + panelHalfWidth;
    const panelBottom = relPos.y - panelHalfHeight;
    const panelTop = relPos.y + panelHalfHeight;

    // Calculate the differences between the moving panel’s edges and candidate grid boundaries.
    const diffLeft = Math.abs(panelLeft - candidateLeft);
    const diffRight = Math.abs(panelRight - candidateRight);
    const diffTop = Math.abs(panelTop - candidateTop);
    const diffBottom = Math.abs(panelBottom - candidateBottom);

    // For snapping purposes we care about both horizontal and vertical misalignment.
    const horizontalMetric = Math.min(diffLeft, diffRight);
    const verticalMetric = Math.min(diffTop, diffBottom);
    const compositeMetric = horizontalMetric + verticalMetric;

    // Choose the candidate with the smallest overall misalignment.
    if (compositeMetric < bestMetric) {
      bestMetric = compositeMetric;
      bestCandidate = {
        panel: candPanel,
        metric: compositeMetric,
        horizontalMetric: horizontalMetric,
        verticalMetric: verticalMetric,
        col: col,
        row: row,
        cellW: cellW,
        cellH: cellH
      };
    }
  }
  return bestCandidate;
}

function gridCalculations(activateColumn, activateRow, bestPanel) {
  // Calculates start and end points for grid lines.
  const minCol = Math.min(0, bestPanel.col) - 1;
  const maxCol = Math.max(0, bestPanel.col) + 1;
  const minRow = Math.min(0, bestPanel.row) - 1;
  const maxRow = Math.max(0, bestPanel.row) + 1;

  const positions = [];
  const halfCellX = bestPanel.cellW / 2;
  const halfCellY = bestPanel.cellH / 2;

  if (activateColumn) {
    for (let i = minCol; i <= maxCol; i++) {
      const x = i * bestPanel.cellW + halfCellX;
      positions.push(x, minRow * bestPanel.cellH + halfCellY, 0);
      positions.push(x, maxRow * bestPanel.cellH + halfCellY, 0);
    }
  }

  if (activateRow) {
    for (let j = minRow; j <= maxRow; j++) {
      const y = j * bestPanel.cellH + halfCellY;
      positions.push(minCol * bestPanel.cellW + halfCellX, y, 0);
      positions.push(maxCol * bestPanel.cellW + halfCellX, y, 0);
    }
  }
  return positions;
}

function gridGeometry(positions) {
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );

  const material = new THREE.LineDashedMaterial({
    color: 0xffffff,
    transparent: true,
    opacity: 0.5,
    dashSize: 0.3,
    gapSize: 0.2,
    depthTest: false
  });

  const grid = new THREE.LineSegments(geometry, material);
  grid.renderOrder = 4;
  grid.name = "grid";
  grid.computeLineDistances();
  return grid;
}

function snapToGrid(panel, candidatePanel, cellWidth, cellHeight, mouse) {
  const panelWorldPos = panel.position.clone();
  const panelLocalPos = candidatePanel.worldToLocal(panelWorldPos.clone());

  // Dynamically adjust threshold based on panel size
  const panelWidth = panel.geometry.parameters.width;
  const panelHeight = panel.geometry.parameters.height;
  const maxDimension = Math.max(panelWidth, panelHeight); // Use longest side for scaling

  const baseThreshold = 0.1; // Original threshold factor
  const snapThreshold = (baseThreshold / maxDimension) * Math.max(cellWidth, cellHeight);

  let newLocalX = panelLocalPos.x;
  let newLocalY = panelLocalPos.y;

  const colIndex = Math.round(panelLocalPos.x / cellWidth);
  const rowIndex = Math.round(panelLocalPos.y / cellHeight);
  const gridCenterX = colIndex * cellWidth;
  const gridCenterY = rowIndex * cellHeight;

  if (panel.panelType === candidatePanel.panelType) {
    // === Horizontal Snapping (X axis) ===
    if (Math.abs(panelLocalPos.x - gridCenterX) < snapThreshold * cellWidth) {
      newLocalX = gridCenterX;
    } else {
      const leftSnapX = gridCenterX - cellWidth / 2;
      const rightSnapX = gridCenterX + cellWidth / 2;
      if (Math.abs(panelLocalPos.x - leftSnapX) < snapThreshold * cellWidth) {
        newLocalX = leftSnapX;
      } else if (Math.abs(panelLocalPos.x - rightSnapX) < snapThreshold * cellWidth) {
        newLocalX = rightSnapX;
      }
    }

    // === Vertical Snapping (Y axis) ===
    if (Math.abs(panelLocalPos.y - gridCenterY) < snapThreshold * cellHeight) {
      newLocalY = gridCenterY;
    } else {
      const bottomSnapY = gridCenterY - cellHeight / 2;
      const topSnapY = gridCenterY + cellHeight / 2;
      if (Math.abs(panelLocalPos.y - bottomSnapY) < snapThreshold * cellHeight) {
        newLocalY = bottomSnapY;
      } else if (Math.abs(panelLocalPos.y - topSnapY) < snapThreshold * cellHeight) {
        newLocalY = topSnapY;
      }
    }
  } else {
    // === Side Snapping (Different Type) ===
    const bestWidth = candidatePanel.geometry.parameters.width;
    const bestHeight = candidatePanel.geometry.parameters.height;

    const bestColIndex = Math.round(panelLocalPos.x / cellWidth);
    const bestRowIndex = Math.round(panelLocalPos.y / cellHeight);
    const bestGridX = bestColIndex * cellWidth;
    const bestGridY = bestRowIndex * cellHeight;

    // Calculate edges of the panels for snapping
    const bestLeft = bestGridX - bestWidth / 2;
    const bestRight = bestGridX + bestWidth / 2;
    const bestTop = bestGridY + bestHeight / 2;
    const bestBottom = bestGridY - bestHeight / 2;

    const panelLeft = panelLocalPos.x - panelWidth / 2;
    const panelRight = panelLocalPos.x + panelWidth / 2;
    const panelTop = panelLocalPos.y + panelHeight / 2;
    const panelBottom = panelLocalPos.y - panelHeight / 2;

    // Snap the panel to the nearest grid line
    if (Math.abs(panelLeft - bestLeft) <= snapThreshold * cellWidth) {
      newLocalX = bestLeft + panelWidth / 2; // Snap left edge
    } else if (Math.abs(panelRight - bestRight) <= snapThreshold * cellWidth) {
      newLocalX = bestRight - panelWidth / 2; // Snap right edge
    } else if (Math.abs(panelLeft - bestGridX) <= snapThreshold * cellWidth) {
      newLocalX = bestGridX + panelWidth / 2; // Snap left to grid
    } else if (Math.abs(panelRight - bestGridX) <= snapThreshold * cellWidth) {
      newLocalX = bestGridX - panelWidth / 2; // Snap right to grid
    }

    if (Math.abs(panelTop - bestTop) <= snapThreshold * cellHeight) {
      newLocalY = bestTop - panelHeight / 2; // Snap top edge
    } else if (Math.abs(panelBottom - bestBottom) <= snapThreshold * cellHeight) {
      newLocalY = bestBottom + panelHeight / 2; // Snap bottom edge
    } else if (Math.abs(panelTop - bestGridY) <= snapThreshold * cellHeight) {
      newLocalY = bestGridY - panelHeight / 2; // Snap top to grid
    } else if (Math.abs(panelBottom - bestGridY) <= snapThreshold * cellHeight) {
      newLocalY = bestGridY + panelHeight / 2; // Snap bottom to grid
    }
  }

  const newLocalPos = new THREE.Vector3(newLocalX, newLocalY, panelLocalPos.z);
  return candidatePanel.localToWorld(newLocalPos);
}

















