
import { Card, Collapse, Flex, Input, Space, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    avaArea,
    polygonDetailsSelector,
    selectedIndicesState, 
    setBackInput
} from "../ThreeJs/ReactStates/states";
import { themeState } from "../lib/atom";
import { CaretRightOutlined } from "@ant-design/icons";

import { POLYGONS } from "../ThreeJs/Global/variables";
import { updateAvaArea, updateSetbackUI } from "../ThreeJs/Segments/SetBacks/Functions";
import { updatePolygonSetbacksSelector } from '../ThreeJs/ReactStates/updates';
import { indexToLabel } from '../ThreeJs/Segments/Labels';


const SegmentDetail = () => {
    const { Panel } = Collapse;

    const theme = useRecoilState(themeState)[0];
    const details = useRecoilValue(polygonDetailsSelector)
    const selectedIndices = useRecoilValue(selectedIndicesState);
    const [inputValues, setInputValues] = useRecoilState(setBackInput);
    const addSetback = useSetRecoilState(updatePolygonSetbacksSelector);
    const [inputValue, setInputValue] = useState(0);
    const [availableArea, setAvailableArea] = useRecoilState(avaArea);
    const [userChanged, setUserChanged] = useState(false);
    const [indexChanged, setIndexChanged] = useState(null);

    useEffect(() => {
        if (selectedIndices.segmentIndex !== null) {
            const initialInputValues = {};
            POLYGONS[selectedIndices.segmentIndex].setBacks.edgesData.forEach((edge, index) => {
                initialInputValues[index] = edge.value;
            });
            setInputValues(initialInputValues);
        }
    }, [selectedIndices.segmentIndex]);

    useEffect(() => {
        if (inputValue >= 0 && userChanged) {
            let index = selectedIndices.segmentIndex;
            addSetback({ setbackAdded: true, segmentIndex: selectedIndices.segmentIndex });
            updateSetbackUI(index, setInputValues, inputValue, indexChanged);
            updateAvaArea(index, setAvailableArea)
        }
        setUserChanged(false);

    }, [inputValue]);

    const handleInputChange = (e) => { 
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setIndexChanged(undefined);
            setInputValue(value);
            setUserChanged(true);
        }
    };

    const handleSingleInputChange = (e, index) => {
        const value = e.target.value;        
        if (/^\d*$/.test(value)) {
            setIndexChanged(index);
            setInputValue(value);
            setUserChanged(true);
        }
    };

    const setbacks = [];
    for (let [index, value] of Object.entries(inputValues)) {
        const label = indexToLabel(index).toLowerCase();
        setbacks.push(
            <Flex style={{ padding: '0 14px 12px 40px' }}>
                <Typography.Text style={{ color: '#555', paddingRight: '40px'}}>
                    <b>{label}</b>
                </Typography.Text>
                <Input
                    size='small'
                    style={{ width: 45, color: '#555' }}
                    onChange={(e) => handleSingleInputChange(e, index)}
                    value={value}
                />
            </Flex>
        )
    }


    return (
        <div>
            <Card style={{ fontSize: '16px' }} bodyStyle={{ padding: 0, fontSize: '16px' }} className='segment__card'>
                <Flex vertical style={{maxHeight: '200px', overflow: 'auto', overflowX: 'hidden',}}
                                    className={` ${theme ? 'custom-scrollbar-dark' : 'custom-scrollbar'}`}>
                    <Flex justify='space-between' align='center' style={{ padding: '14px' }}>
                        <Space.Compact direction='vertical'>
                            <Typography.Text strong>
                                Pitch
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: '12px' }}>
                                {details.pitch !== null ? `${details.pitch}°` : 'N/A'}
                            </Typography.Text>
                        </Space.Compact>
                        <Space.Compact direction='vertical'>
                            <Typography.Text strong>
                                Azimuth
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: '12px' }}>
                                {details.azimu !== null ? `${details.azimu}°` : 'N/A'}
                            </Typography.Text>
                        </Space.Compact>
                        <Space.Compact direction='vertical'>
                            <Typography.Text strong>
                                Area
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: '12px' }}>
                                {details.area !== null ? `${parseFloat(details.area).toFixed(2)} sqft` : 'N/A'}
                            </Typography.Text>
                        </Space.Compact>
                    </Flex>
                    <Space.Compact direction='vertical' style={{ padding: '0 0 8px 14px' }}>
                        <Typography.Text strong>
                            Available Area
                        </Typography.Text>
                        <Typography.Text style={{ fontSize: '12px' }}>
                            {`${availableArea !== '' ? `${availableArea}` : `${parseFloat(details.area).toFixed(2)} sqft`}`}
                        </Typography.Text>
                    </Space.Compact>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) =>
                            <CaretRightOutlined style={{ color: '#ccc' }} rotate={isActive ? 90 : 0} />
                        }
                    >
                        <Panel
                            header={<Typography.Text strong>Setbacks(in)</Typography.Text>}
                            key="1"
                            extra={
                                <Input
                                    style={{ width: 45 }}
                                    onClick={event => event.stopPropagation()}
                                    onChange={handleInputChange}
                                    value={Object.values(inputValues).every(val => val === Object.values(inputValues)[0]) ? Object.values(inputValues)[0] : null}
                                />
                            }
                        >
                            {setbacks}
                        </Panel>
                    </Collapse>
                </Flex>
            </Card>
        </div>
    )
}

export default SegmentDetail